export const quotes:string[] = [
    "The betterment of the world can be accomplished through pure and goodly deeds, through commendable and seemly conduct.",
    "Beware, O people of Baha, lest ye walk in the ways of them whose words differ from their deeds.",
    "O Son of Being! Bring thyself to account each day ere thou art summoned to a reckoning...",
    "Say: O brethren! Let deeds, not words, be your adorning.",
    "Holy words and pure and goodly deeds ascend unto the heaven of celestial glory.",
    "Truthfulness is the foundation of all human virtues.",
    "Without truthfulness progress and success, in all the worlds of God, are impossible for any soul.",
    "Beautify your tongues, O people, with truthfulness, and adorn your souls with the ornament of honesty.",
    "Let your eye be chaste, your hand faithful, your tongue truthful and your heart enlightened.",
    "They who dwell within the tabernacle of God, and are established upon the seats of everlasting glory, will refuse, though they be dying of hunger, to stretch their hands to seize unlawfully the property of their neighbor, however vile and worthless he may be.",
    " A kindly tongue is the lodestone of the hearts of men. It is the bread of the spirit, it clotheth the words with meaning, it is the fountain of the light of wisdom and understanding...",
    "O ye beloved of the Lord! In this sacred Dispensation, conflict and contention are in no wise permitted. Every aggressor deprives himself of God's grace.",
    "Nothing whatever can, in this Day, inflict a greater harm upon this Cause than dissension and strife, contention, estrangement and apathy, among the loved ones of God.",
    "Do not be content with showing friendship in words alone, let your heart burn with loving-kindness for all who may cross your path.",
    "When a thought of war comes, oppose it by a stronger thought of peace. A thought of hatred must be destroyed by a more powerful thought of love.",
    "...backbiting quencheth the light of the heart, and extinguisheth the life of the soul.",
    "Breathe not the sins of others so long as thou art thyself a sinner.",
    "Speak no evil, that thou mayest not hear it spoken unto thee, and magnify not the faults of others that thine own faults may not appear great...",
    "O Son of Being! How couldst thou forget thine own faults and busy thyself with the faults of others? Whoso doeth this is accursed of me.",
    "Gird up the loins of thine endeavor, that haply thou mayest guide thy neighbor to the law of God, the Most Merciful. Such an act, verily, excelleth all other acts in the sight of God, the All-Possessing, the Most High.",
    "Whoso ariseth to teach Our Cause must needs detach himself from all earthly things, and regard, at all times, the triumph of Our Faith as his supreme objective.",
    "Be unrestrained as the wind, while carrying the Message of Him Who hath caused the Dawn of Divine Guidance to break.",
    "When the victory arriveth, every man shall profess himself as believer and shall hasten to the shelter of God's Faith. Happy are they who in the days of world-encompassing trials have stood fast in the Cause and refused to swerve from its truth.",
    "O SON OF BEING! Make mention of Me on My earth, that in My heaven I may remember thee, thus shall Mine eyes and thine be solaced.",
    "All men have been created to carry forward an ever-advancing civilization ",
    "The Purpose of the one true God, exalted be His glory, in revealing Himself unto men is to lay bare those gems that lie hidden within the mine of their true and inmost selves.",
    "A new life is, in this age, stirring within all the peoples of the earth; and yet none hath discovered its cause or perceived its motive.",
    "The betterment of the world can be accomplished through pure and goodly deeds, through commendable and seemly conduct.",
    "Beware, O people of Baha, lest ye walk in the ways of them whose words differ from their deeds.",
    "O Son of Being! Bring thyself to account each day ere thou art summoned to a reckoning...",
    "Say: O brethren! Let deeds, not words, be your adorning.",
    "Holy words and pure and goodly deeds ascend unto the heaven of celestial glory.",
    "Truthfulness is the foundation of all human virtues.",
    "Without truthfulness progress and success, in all the worlds of God, are impossible for any soul.",
    "Beautify your tongues, O people, with truthfulness, and adorn your souls with the ornament of honesty.",
    "Let your eye be chaste, your hand faithful, your tongue truthful and your heart enlightened.",
    "They who dwell within the tabernacle of God, and are established upon the seats of everlasting glory, will refuse, though they be dying of hunger, to stretch their hands to seize unlawfully the property of their neighbor, however vile and worthless he may be.",
    " A kindly tongue is the lodestone of the hearts of men. It is the bread of the spirit, it clotheth the words with meaning, it is the fountain of the light of wisdom and understanding...",
    "O ye beloved of the Lord! In this sacred Dispensation, conflict and contention are in no wise permitted. Every aggressor deprives himself of God's grace.",
    "Nothing whatever can, in this Day, inflict a greater harm upon this Cause than dissension and strife, contention, estrangement and apathy, among the loved ones of God.",
    "Do not be content with showing friendship in words alone, let your heart burn with loving-kindness for all who may cross your path.",
    "When a thought of war comes, oppose it by a stronger thought of peace. A thought of hatred must be destroyed by a more powerful thought of love.",
    "...backbiting quencheth the light of the heart, and extinguisheth the life of the soul.",
    "Breathe not the sins of others so long as thou art thyself a sinner.",
];

import React from 'react';
import {Accordion} from 'react-bootstrap';
import {SoundCloudPlayer} from "./SoundCloudPlayer";
import {YouTubePlayer} from "./YouTubePlayer";

// @ts-ignore
export const JUNETEENTH = () => (
    <div>
        <p/>
        <h2>Juneteenth</h2>

        <Accordion defaultActiveKey="1">
             <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <center>
                        <h4>Juneteenth 2024 - Portland Oregon</h4>
                        <h4>(click here to show/hide video/audio)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <h4>
                            <SoundCloudPlayer
                                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1858971504&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
                            <YouTubePlayer url="https://www.youtube.com/embed/An_v98Nr_Bc?si=TjPaf-EYvg4zM29h"/>
                        </h4>
                    </div>
                </Accordion.Body>
             </Accordion.Item>
        </Accordion>
    </div>
)


import React from 'react';
// @ts-ignore
import styled from 'styled-components';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';


const Styles = styled.div`
    .navbar {
        background-color : green;
    }

    .navbar-brand, .navbar-nav .nav-link {
    color : #bbb;

    &:hover
    {
      color: white;
    }
  }`;

 export const NavigationBar = () => (
     <Styles>
         <Navbar expand="lg" bg="dark" variant="dark">
             <Navbar.Brand href="/">
                 <img
                     alt=""
                     src={"/favicon.ico"}
                     width="30"
                     height="30"
                     className="d-inline-block align-top"
                 />{' '}
                 Ancient Cedars
             </Navbar.Brand>
             <Navbar.Toggle aria-controls="basic-navbar-nav"/>
             <Navbar.Collapse id="basic-navbar-nav">
                 <Nav className="ml-auto">
                     <Nav.Item><Nav.Link href="/">Home</Nav.Link></Nav.Item>
                     <NavDropdown title="Ceremony" id="ceremony">
                         <NavDropdown.Item  href="/IBC2024">2024</NavDropdown.Item>
                         <NavDropdown.Item  href="/IBC2023">2023</NavDropdown.Item>
                         <NavDropdown.Item  href="/IBC2022">2022</NavDropdown.Item>
                         <NavDropdown.Item  href="/IBC2021">2021</NavDropdown.Item>
                         <NavDropdown.Item  href="/IBC2020">2020</NavDropdown.Item>
                     </NavDropdown>
                     <NavDropdown title="Community Issues" id="ceremony">
                         <NavDropdown.Item  href="/Juneteenth">Juneteenth 2024 Portland</NavDropdown.Item>
                         <NavDropdown.Item  href="/MMIW">Missing and Murdered Indigenous Women</NavDropdown.Item>
                         <NavDropdown.Item  href="/Food">Food Sovereignty</NavDropdown.Item>
                     </NavDropdown>
                     <Nav.Item><Nav.Link href="/holydays">Holy Days</Nav.Link></Nav.Item>
                     <Nav.Item><Nav.Link href="/chants">Chants</Nav.Link></Nav.Item>
                     <Nav.Item><Nav.Link href="/music">Music and Songs</Nav.Link></Nav.Item>
                     <Nav.Item><Nav.Link href="/firesides">Firesides</Nav.Link></Nav.Item>
                     <Nav.Item><Nav.Link href="/circles">Circles of Wisdom and Knowledge</Nav.Link></Nav.Item>
                     <Nav.Item><Nav.Link href="/writings">Writings</Nav.Link></Nav.Item>
                     <NavDropdown title="Quick links" id="quick-links">
                         <NavDropdown.Item href="/extras">Extras</NavDropdown.Item>
                         <NavDropdown.Divider />
                         <NavDropdown.Item  href="/about">About</NavDropdown.Item>
                         <NavDropdown.Item  href="/contact">Contact</NavDropdown.Item>
                     </NavDropdown>

                 </Nav>
            </Navbar.Collapse>
        </Navbar>
     </Styles>
 )









import React from "react";

// @ts-ignore
import styled from 'styled-components';

const SoundCloudStyles = styled.div`
  
  .scStyle {
    font-size: 10px;
    color: #c3c32e;
    line-break: anywhere;
    word-break: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
    font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;
    font-weight: 100;
  }
`;

export const SoundCloudPlayer = (props: { url: string;}) => {
    return (
        <SoundCloudStyles>
            <div className='scStyle'>
                <iframe title="AZIO" width="70%" height="166" scrolling="no" frameBorder="no" allow="autoplay" src={props.url}>
                </iframe>
            </div>
        </SoundCloudStyles>
    )
}

import React from 'react';
import {YouTubePlayer} from "./YouTubePlayer";


// @ts-ignore
export const MMIW = () => (
    <div>
        <p/>
        <h2>Murdered and Missing Indigenous Women</h2>

        <h4>Links/Videos</h4>
    <ol>
        <li><a href={process.env.PUBLIC_URL + "/Documents/AncientCedars MMIWG.pdf"}>Dr. Littlebrave Beaston's Presentation</a></li>

        <li><a href={"https://www.uihi.org/resources/missing-and-murdered-indigenous-women-girls/"}>Urban Indian Health Institiute - A snapshot of data from 71 urban cities in the United States</a></li>

        <li><a href={"https://www.uihi.org/projects/our-bodies-our-stories/"}>Urban Indian Health Institiute - Our Bodies, Our Stories/</a></li>

        <li><a href={"https://www.uihi.org/resources/mmiwg-we-demand-more/"}>Urban Indian Health Institiute - MMIWG: We Demand More</a></li>

        <li><a href={"https://www.seattletimes.com/seattle-news/homeless/survey-reveals-high-rates-of-sexual-assault-among-native-american-women-many-of-them-homeless/"}>Seattle Times - Nearly every Native American woman in Seattle survey said she was raped or coerced into sex</a></li>

        <li><a href={"https://www.bia.gov/service/mmu/missing-and-murdered-indigenous-people-crisis"}>US Department of Interior (Indian Affairs) Missing and Murdered Indigenous People Crisis</a></li>

        <li><a href={"https://www.ojp.gov/pdffiles1/nij/grants/223691.pdf"}>US Department of Justice - Violence Against American Indian and Alaska Native Women and the Criminal Justice Response: What is Known</a></li>

        <li><a href={"https://www.cdc.gov/violenceprevention/sexualviolence/rpe/index.html"}>CDC - Rape Prevention and Education Program</a></li>

        <li><a href={"https://www.cdc.gov/violenceprevention/intimatepartnerviolence/prevention.html"}>CDC - Intimate Partner Violence: Prevention Strategies</a></li>

        <li><a href={"https://www.miwsac.org/"}>The Minnesota Indian Women’s Sexual Assault Coalition</a></li>

        <li><a href={"https://www.facebook.com/NativeJournalists/"}>Facebook - Native American Journalist Association - Native Journalist</a></li>

        <li><a href={"https://najanewsroom.com/reporting-guides/"}>Native American Journalists Association - Reporting Guides</a></li>

        <li><a href={"https://www.somebodysdaughter.com/"}>Somebody's Daughter</a></li>

        <li><a href={" https://mshoop.org/"}>Mending the Sacred Hoop</a></li>

        <li>
            Prayer for Murdered and Missiong Indigenous Women
            <YouTubePlayer url="https://www.youtube.com/embed/4KMYi-2BMsI"/>
        </li>

        <li>
            REDress Project by Jaime Black - March - 2011
            <YouTubePlayer url="https://www.youtube.com/embed/iaX8ks7CcPg"/>
        </li>

        <li>
            Violence Against American Indian and Alaska Native Women and Men
            <YouTubePlayer url="https://www.youtube.com/embed/ESADNNHM4II"/>
        </li>

        <li>
            The REDress Project at the National Museum of the American Indian
            <YouTubePlayer url="https://www.youtube.com/embed/lH7FuxzrFvs"/>
        </li>

        <li>
            Finding Dawn
            <YouTubePlayer url="https://www.youtube.com/embed/f-0Z-UoQ3VY"/>
        </li>

    </ol>
    </div>
);

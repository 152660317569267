import React from 'react';
import {Accordion} from "react-bootstrap";
import {YouTubePlayer} from "./YouTubePlayer";

export const Music = () => (
    <div>
        <p/>
        <h2>Songs and Music</h2>
        <p/>
        <Accordion defaultActiveKey="3">
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <center>
                        <h4>My Prayer - Nabil & Karim</h4>
                        <h4>(click here hear</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <YouTubePlayer url={"https://www.youtube.com/embed/PqcOGFaUJAI"}/>
                    </center>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    <center>
                        <h4>Ali Youssefi - UNITE</h4>
                        <h4>(click here hear</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <YouTubePlayer url={"https://www.youtube.com/embed/zfPzUZ_25yw"}/>
                    </center>
                </Accordion.Body>
            </Accordion.Item>
         </Accordion>
    </div>
)

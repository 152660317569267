import React from 'react';

import {SoundCloudPlayer} from './SoundCloudPlayer';
import {YouTubePlayer} from "./YouTubePlayer";
import {Accordion}  from "react-bootstrap";

// @ts-ignore
export const IBC2021 = () => (
    <div>
        <p/>
        <h2>Indigenous Bahá'í Ceremony</h2>
        <Accordion defaultActiveKey="100">
            <Accordion.Item eventKey="15">
                <Accordion.Header>
                    <center>
                        <h4>Kevin Locke</h4>
                        <h4>November 2021</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2021_11.jpg"} width={"45%"} height={"45%"} alt={"Kevin Locke"} />
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1172900944%3Fsecret_token%3Ds-b8rGVqKRdk0&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/0sgwEj4Nh-M" />
                        00:00:00  - Introductions<br/>
                        00:02:24  - Kevin Locke<br/>
                        00:57:10  - Reflections<br/>
                    </div>
                </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="14">
                <Accordion.Header>
                    <center>
                        <h4>Louise Profeit-LeBlanc and Alice Bathke</h4>
                        <h4>October 2021</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2021_10.jpg"} width={"45%"} height={"45%"} alt={"Louise Profeit-LeBlanc and Alice Bathke"} />
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1147388320%3Fsecret_token%3Ds-kQIBPsZUHH1&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/AG7qbSz8ags" />
                        00:00:00  - Introductions<br/>
                        00:06:30  - Alice Bathke<br/>
                        01:05:15  - Louise Profeit-LeBlanc<br/>
                        01:49:00  - Reflection Mary Gubatayo<br/>
                    </div>
                </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="13">
                <Accordion.Header>
                    <center>
                        <h4>Ferris Paisano and Chris Cullen</h4>
                        <h4>September 2021</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2021_09.jpg"} width={"45%"} height={"45%"} alt={"Ferris Paisano and Chris Cullen"} />
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1139176204%3Fsecret_token%3Ds-RA4z4wbIC1a&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/O8FcGLGog00" />
                        00:00:00  - Introductions<br/>
                        00:06:20  - Ferris Paisano<br/>
                        00:43:00  - Chris Cullen<br/>
                        01:38:52  - Reflections<br/>
                    </div>
                </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="12">
                <Accordion.Header>
                    <center>
                        <h4>Roberta Charles</h4>
                        <h4>August 2021</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2021_08.jpg"} width={"45%"} height={"45%"} alt={"Roberta Charles"} />
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1139194609%3Fsecret_token%3Ds-j65UR6CJJWp&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/GUc1UKtk6LI" />
                        00:00:00  - Introduction<br/>
                        00:01:15  - Roberta Charles<br/>
                    </div>
                </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="11">
                <Accordion.Header>
                    <center>
                        <h4>Toby Joseph and George Holly</h4>
                        <h4>July 2021</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2021_07.jpg"} width={"45%"} height={"45%"} alt={"Toby Joseph and George Holly"} />
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1139544235%3Fsecret_token%3Ds-DQFQqZqUNE1&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/ipJ04V6uz_4" />
                        00:00:00  - Introductions<br/>
                        00:03:35  - George Holly<br/>
                        00:57:40  - Toby Joseph Sr<br/>
                    </div>
                </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="10">
                <Accordion.Header>
                    <center>
                        <h4>Mr. Bob Watts and Dr. Scott Tyler</h4>
                        <h4>June 2021</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2021_06.jpg"} width={"45%"} height={"45%"} alt={"Mr. Bob Watts and Dr. Scott Tyler"} />
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1139572042%3Fsecret_token%3Ds-xw4k4zgsSj3&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/sI418Hd2BdI" />
                        00:00:00  - Introductions<br/>
                        00:14:35  - Bob Watts<br/>
                        01:29:00  - Scott Tyler<br/>
                    </div>
                </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="9">
                <Accordion.Header>
                    <center>
                        <h4>Deloria Bighorn and Kamao Cappo</h4>
                        <h4>May 2021</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2021_05.jpg"} width={"45%"} height={"45%"} alt={"Deloria Bighorn and Kamao Cappo"}/>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1139573917%3Fsecret_token%3Ds-NdtjiQfLaX5&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/PP3YY7gg7OY" />
                        00:00:00  - Introductions<br/>
                        00:05:28  - Jordan Bighorn<br/>
                        00:17:30  - Kai Bighorn<br/>
                        00:28:09  - Deloria Bighorn<br/>
                        00:51:03  - Kamao Cappo<br/>
                        01:52:30  - Discussion<br/>
                    </div>
                </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="8">
                <Accordion.Header>
                    <center>
                        <h4>Dr. Littlebrave Beaston</h4>
                        <h4>April 2021</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2021_04.png"} width={"45%"} height={"45%"} alt={"Dr. Littlebrave Beaston"}/>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <p><a href={process.env.PUBLIC_URL + "/Documents/LB Ancient Cedars 4.25.21 .pdf"}>
                            Click to Download the PDF for the Ceremony presentation</a></p>
                        <p><a href={process.env.PUBLIC_URL + "/Documents/AmericanBaha'iAdditions.pdf"}>
                            Click to Download the PDF for the American Bahá'í Additions</a></p>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1037147542%3Fsecret_token%3Ds-bG0PgRtX7WH&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/y7Ep_N_j8m0" />
                        00:00:00  - Introductions and prayers<br/>
                        00:38:00  - Littlebrave Beaston<br/>
                        01:47:10  - Discussion<br/>
                    </div>
                </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="7">
                <Accordion.Header>
                    <center>
                        <h4>Bitahnii Wayne Wilson and John Bathke</h4>
                        <h4>March 2021</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2021_03.jpg"} width={"45%"} height={"45%"}  alt={"Bitahnii Wayne Wilson and John Bathke"}/>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1037374882%3Fsecret_token%3Ds-ZmmphD2uWDY&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/oEPG6Omw3DA" />
                        00:00:00  - Introductions<br/>
                        00:10:00  - Bitahnii Wayne Wilson<br/>
                        01:34:00  - John Bathke<br/>
                    </div>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
                <Accordion.Header>
                    <center>
                        <h4>Kevin Locke, John Gubatayo, Toby Joseph</h4>
                        <h4>February 2021</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2021_02.jpg"} width={"45%"} height={"45%"} alt={"Kevin Locke, John Gubatayo, Toby Joseph Sr."} />
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1006468912%3Fsecret_token%3Ds-QCkSwT4tVfY&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/PiYXjKnuLeI" />
                        00:00:00  - Introductions<br/>
                        00:01:00  - Kevin Locke<br/>
                        00:42:00  - John Gubatayao<br/>
                        01:38:00  - Toby Joseph Sr<br/>
                    </div>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
                <Accordion.Header>
                    <center>
                        <h4>Gene Tagaban</h4>
                        <h4>January 2021</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2021_01.jpg"} width={"45%"} height={"45%"} alt={"Gene Tagaban"} />
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/981675769%3Fsecret_token%3Ds-CJQD5J7TaqA&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/thWPh8OQ6BU" />
                        00:00:00  - Introduction<br/>
                        00:03:00  - Gene Tagaban<br/>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
);
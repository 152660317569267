import * as React from "react";
import { Form, IFields, required, isEmail, maxLength } from "./Form";
// @ts-ignore
import { Field } from './Field';

export const Contact: React.FC = () => {
    const fields: IFields = {
        name: {
            id: "name",
            label: "Your Name",
            validation: { rule: required }
        },
        email: {
            id: "email",
            label: "Your Email",
            validation: { rule: isEmail }
        },
        reason: {
            id: "reason",
            label: "Let us know why",
            editor: "dropdown",
            options: ["", "General feedback","Suggest individuals for Ceremony", "Add more videos under Extras", "Add more writings"],
            validation: { rule: required }
        },
        notes: {
            id: "notes",
            label: "Your Message",
            editor: "multilinetextbox",
            validation: { rule: maxLength, args: 1500 }

        }
    };
    return (
        <Form
            action="https://api.emailjs.com/api/v1.0/email/send"
            fields={fields}
            render={() => (
                <React.Fragment>
                    <h2> Send us a message</h2>
                    <div className="alert alert-info" role="alert">
                        Enter information below and we'll get back to you as soon as we
                        can.
                    </div>
                    <Field {...fields.name} />
                    <Field {...fields.email} />
                    <Field {...fields.reason} />
                    <Field {...fields.notes} />
                </React.Fragment>
            )}
        />
    );
};

import React from 'react';
import {Accordion} from "react-bootstrap";
import {YouTubePlayer} from "./YouTubePlayer";


export const Firesides = () => (
    <div>
        <h2>Firesides</h2>
        <Accordion defaultActiveKey="3">
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <center>
                        <h4>Our Elders Speaak - Sam Bald Eagle Augustine</h4>
                        <h4>(click here hear</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <YouTubePlayer url={"https://www.youtube.com/embed/Jm7PgWVW5pg"}/>
                    </center>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>

    </div>
)

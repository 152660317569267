import React from 'react';

// @ts-ignore
export const June2023 = () => (
        <div>
            <h4>African American Indian Drumming</h4>
            <h4>Portland</h4>
            <h4>Sunday 21st May. 2023</h4>

            <iframe width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay"
                    title="Sound African American and Indian Drumming Portland May 2023"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1523678059&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
            <iframe width="884" height="497" src="https://www.youtube.com/embed/6gzukxYlk0c"
                    title="African American and Indian Drumming Portland May 2023" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
        </div>
)

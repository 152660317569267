import React from 'react';
import {SoundCloudPlayer} from './SoundCloudPlayer';
import {Accordion} from "react-bootstrap";
import {YouTubePlayer} from "./YouTubePlayer";

export const Chants = () => (
    <div>
        <p/>
        <h2>Chants and Prayers</h2>
        <p/>
        <Accordion defaultActiveKey="3">
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <center>
                        <h4>Chant - Scott Tyler</h4>                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <YouTubePlayer url={"https://www.youtube.com/embed/QFI7IxKIc2I"}/>
                    </center>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    <center>
                        <h4>Blessed is the Spot in Hul'qumi'num</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <YouTubePlayer url={"https://www.youtube.com/embed/VTI24MCSf-I"}/>
                    </center>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <center>
                        <h4>Lorintha Umtuch Chants</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <p>Nightingale in Paradise - playlist</p>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1200597610&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <br/>
                        <p>Individual Chants</p>
                        <p>Sweet Scented Streams</p>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/972109780&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <p>Remover of Difficulties (Saskatchewan Style)</p>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/972109774&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <p>Refresh and Gladden My Spirit</p>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/972109762&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <p>Remover of Difficulties (NAC Style)</p>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/972109753&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <p>Triumph of the Cause (Coastal Style)</p>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/972109750&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <p>O God</p>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/972109732&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <p>Short Healing Prayer</p>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/972109714&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <p>Triumph of the Cause (with Rattle)</p>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/972109705&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                    </center>
                </Accordion.Body>
            </Accordion.Item>
          </Accordion>
    </div>
)

import React from 'react';
import {Accordion} from "react-bootstrap";
import {YouTubePlayer} from "./YouTubePlayer";


export const HolyDays = () => (
    <div>
        <p/>
        <h2>Holy Day Celebrations</h2>
        <p/>
        <Accordion defaultActiveKey="3">
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <center>
                        <h4>Day of the Covenant Wednesday 24th November 2021</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Day of the Covenant 2021.jpg"} width={"45%"} height={"45%"} alt={"Day of the Covenant 2021"} />
                        <h4><a href={"https://canyonrecords.com/"}>
                            Special Thanks to Canyon Records and Carlos Nakai - Click this link support Carlos Nakai and Canyon Records by purchasing a CD at their website</a></h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <YouTubePlayer url="https://www.youtube.com/embed/vPoxmhmdF8Y" />
                    </center>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    <center>
                        <h4>Blessed is the Spot in Hul'qumi'num</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <YouTubePlayer url={"https://www.youtube.com/embed/VTI24MCSf-I"}/>
                    </center>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <center>
                        <h4>Ascension of Abdu’l-Bahá - Online Commemoration from the Bahá’í House of Worship for N. America 2020</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <YouTubePlayer url="https://www.youtube.com/embed/BSPnUyz24kA" />
                    </center>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>


    </div>
)

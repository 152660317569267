import React from 'react';
import {Accordion} from "react-bootstrap";
import {ASML_SHOGHI_EFFENDI} from "./Writings/A_Special_Measure_of_Love";
import {TABLETS_DIVINE_PLAN} from "./Writings/Tablets_of_the_Divine_Plan";


export const Writings = () => (
    <div>
        <p/>
        <h2>Writings</h2>
        <p/>
        <Accordion defaultActiveKey="3">
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <center>
                        <h3>A Special Measure of Love</h3>
                        <h4>(click here to read)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <ASML_SHOGHI_EFFENDI/>
                    </center>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    <center>
                        <h3>Tablets of the Divine Plan</h3>
                        <h4>(click here to read)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <TABLETS_DIVINE_PLAN/>
                    </center>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
)

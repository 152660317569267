import React from 'react';
import {SlideShow} from "./SlideShow";
import { quotes } from "./Quotes";
import { images } from "./Images";

export const Home = () => {
    return (
        <SlideShow images={images} quotes={quotes}/>
     )
}






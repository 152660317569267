import React from 'react';
import {YouTubePlayer} from "./YouTubePlayer";
import {Accordion} from "react-bootstrap";

// @ts-ignore
export const Extras = () => (
    <div>
        <p/>
        <h3>Videos</h3>
        <p/>
        <Accordion defaultActiveKey="3">
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <center>
                    <h4>Haa Tóo Yéi Yatee Haa Yoo X̱ʼatángi: Our Language is Inside Us - George Holly - 2022</h4>
                    <h4>(click here to see)</h4>
                </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <YouTubePlayer url={"https://www.youtube.com/embed/MV1nRSIhmqg"}/>
                    </center>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    <center>
                        <h4>Being Heard in the Face of Paternalism - A Rich Tapestry - 2020</h4>
                        <h4>(click here to see)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <YouTubePlayer url={"https://www.youtube.com/embed/53BKaPM-yqo"}/>
                    </center>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <center>
                        <h4>First Native Bahá'í Council Fire - Pine Springs 1962 - part 1</h4>
                        <h4>(click here to see)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <YouTubePlayer url={"https://www.youtube.com/embed/YeyDCdz6B98"} />
                    </center>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
)

import React from 'react';
import {Accordion} from "react-bootstrap";

export const ASML_SHOGHI_EFFENDI = () => (
    <div>
        <Accordion defaultActiveKey="3">
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <center>
                        <h4>Overview</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <p>"Seven years ago The Universal House of Justice transmitted to all
                            National Spiritual Assemblies a compilation arranged
                            chronologically from the writings of Shoghi Effendi and from
                            letters written on his behalf regarding the importance and nature of
                            the teaching work among the masses. The compilation is
                            supplemented herein by portions of three letters written by the
                            Supreme Institution itself in 1964, 1966, and 1972. One of these
                            letters has been published in Wellspring of Guidance; two have not
                            been previously published. In publishing these extracts, the
                            National Spiritual Assembly shares the hope and belief expressed
                            by The Universal House of Justice that, “this compilation will guide
                            and assist you in better appreciating the manner of the presentation
                            of the teachings of the Faith, the attitude that must govern those
                            responsible for enrolling new believers, the need to educate the
                            newly-enrolled Bahá’ís, to deepen them in the teachings and to
                            wean them gradually away from their old allegiances, the necessity
                            to keep a proper balance between expansion and consolidation, the
                            significance of the participation of the native believers of each
                            country in the teaching work and in the administration of affairs of
                            the community, the formulation of budgets within the financial
                            capabilities of the community, the importance of fostering the spirit
                            of self-sacrifice in the hearts of the friends, the worthy goal for each
                            national community to become self-supporting, the preferability of
                            individuality of expression to absolute uniformity, within the
                            framework of the Administrative Order, and the lasting value of
                            dedication and devotion when engaged in the teaching work.”</p>
                        <p>NATIONAL SPIRITUAL ASSEMBLY OF<br/>
                            THE BAHÁ’ÍS OF THE UNITED STATES</p>
                        <p><a href={process.env.PUBLIC_URL + "/Documents/A_Special_Measure_of_Love.pdf"}>
                            Click to Download pdf</a></p>
                        <p>Copyright © 1974 by the<br/>
                            National Spiritual Assembly of the<br/>
                            Bahá’ís of the United States</p>                    </center>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    <center>
                        <h4>Messages from Shoghi Effendi</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <p>“Let anyone who feels the urge among the participators in this crusade, which embraces all
                            the races, all the
                            republics, classes and denominations of the entire Western Hemisphere, arise, and,
                            circumstances permitting,
                            direct in particular the attention, and win eventually the unqualified adherence, of the
                            Negro, the Indian,
                            the Eskimo, and Jewish races to his Faith. No more laudable and meritorious service can be
                            rendered the
                            Cause of God, at the present hour, than a successful effort to enhance the diversity of the
                            members of the
                            American Bahá’í community by swelling the ranks of the Faith through the enrollment of the
                            members of these
                            races. A blending of these highly differentiated elements of the human race, harmoniously
                            interwoven into
                            the fabric of an all-embracing Bahá’í fraternity, and assimilated through the dynamic
                            process of a
                            divinely-appointed Administrative Order, and contributing each its share to the enrichment
                            and glory of
                            Bahá’í community life, is surely an achievement the contemplation of which must warm and
                            thrill every Bahá’í
                            heart.” –Shoghi Effendi
                            <br/><i>(From letter dated December 25, 1938, to the American believers) [Page1 original
                                ASML]</i></p>
                        <p>“Smallness of numbers, lack of skilled teachers, and modesty of means should not discourage
                            or deter them.
                            They must remember the glorious history of the Cause, which . . . was established by
                            dedicated souls who,
                            for the most part, were neither rich, famous, nor well educated, but whose devotion, zeal
                            and self-sacrifice
                            overcame every obstacle and won miraculous victories for the Faith of God. Such spiritual
                            victories can now
                            be won for India and Burma by the friends. Let them dedicate themselves—young and old, men
                            and women,
                            alike—and go forth and settle in new districts, travel and teach, in spite of lack of
                            experience, and be
                            assured that Bahá’u’lláh has promised to aid all those who arise in His name. His strength
                            will sustain
                            them; their own weakness is unimportant”
                            <br/><i>(From letter dated June 29, 1941, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly of
                                India and Burma) [Pages 1-2 original ASML]</i></p>
                        <p>“Many are the souls who, in this Holy Cause, without either worldly means or knowledge, have
                            set ablaze the
                            hearts of others with the divine love and rendered the Faith imperishable services. The
                            Guardian hopes that
                            you will be able to do likewise.”
                            <br/><i>(From letter dated October 5, 1941, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly of
                                India and Burma) [Page 2 original ASML]</i></p>
                        <p>“There is no doubt that the poorer classes should be taught the Cause and given every
                            opportunity to embrace
                            it. More especially in order to demonstrate to people our cardinal lack of prejudice, class
                            prejudice as
                            much as any other kind of prejudice. However, he feels that the great point is to confirm
                            people of true
                            capacity and ability—from whatever social stratum they may be—because the Cause needs now,
                            and will ever
                            increasingly need, souls of great ability who can bring it before the public at large,
                            administer its
                            ever-growing affairs, and contribute to its advancement in every field.”
                            <br/><i>(From letter dated October 30, 1941, written on behalf of Shoghi Effendi to an
                                individual believer)
                                [Page 2
                                original ASML]</i></p>
                        <p>“That is perhaps what is most glorious about our present activities all over the world, that
                            we, a band not
                            large in numbers, not possessing financial backing or the prestige of great names, should,
                            in the name of
                            our beloved Faith, be forging ahead at such a pace, and demonstrating to future and present
                            generations that
                            it is the God -given qualities of our religion that are raising it up and not the transient
                            support of
                            worldly fame and power. All that will come later, when it has been made clear beyond the
                            shadow of a doubt
                            that what raised aloft the banner of Bahá’u’lláh was the love, sacrifice and devotion of His
                            humble
                            followers and the change that His teachings wrought in their hearts and lives.”
                            <br/><i>(From letter dated June 20, I 942, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly of
                                the British Isles) [Pages 2-3 original ASML]</i></p>
                        <p>“The initial contact already established, in the concluding years of the first Bahá’í
                            century, in obedience
                            to ‘Abdu’l-Bahá’ s mandate, with the Cherokee and Oneida Indians in North Carolina and
                            Wisconsin, with the
                            Patagonian, the Mexican and the Inca Indians, and the Mayans in Argentina, Mexico, Peru and
                            Yucatan,
                            respectively, should, as the Latin American Bahá’í communities gain in stature and strength,
                            be consolidated
                            and extended. A special effort should be exerted to secure the unqualified adherence of
                            members of some of
                            their tribes to the Faith, their subsequent election to its councils, and their unreserved
                            support of the
                            organized attempts that will have to be made in the future by the projected National
                            Assemblies for the
                            large-scale conversion of Indian races to the Faith of Bahá’u’lláh.”—Shoghi Effendi
                            <br/><i>(From letter dated June 5, 1947, to the American believers) [Page 3 original
                                ASML]</i></p>
                        <p>“Nor should any of the pioneers, at this early stage in the upbuilding of Bahá’í national
                            communities,
                            overlook the fundamental prerequisite for any successful teaching enterprise, which is to
                            adapt the
                            presentation of the fundamental principles of their Faith to the cultural and religious
                            backgrounds, the
                            ideologies, and the temperament of the diverse races and nations whom they are called upon
                            to enlighten and
                            attract. The susceptibilities of these races and nations . . . differing widely in their
                            customs and
                            standards of living, should at all times be carefully considered, and under no circumstances
                            neglected.”–Shoghi Effendi
                            <br/><i>(From letter dated June 5, 19 47, to the American believers) [Page 3 original
                                ASML]</i></p>
                        <p>“The primary reason for anyone becoming a Bahá’í must of course be because he has come to
                            believe the
                            doctrines, the teachings and the Order of Bahá’u’lláh are the correct thing for this stage
                            in the world’s
                            evolution. The Bahá’ís themselves as a body have one great advantage; they are sincerely
                            convinced
                            Bahá’u’lláh is right; they have a plan, and they are trying to follow it. But to pretend
                            they are perfect,
                            that the Bahá’ís of the future will not be a hundred times more mature, better balanced,
                            more exemplary in
                            their conduct would be foolish.”
                            <br/><i>(From letter dated July 5, I 947, written on behalf of Shoghi Effendi to an
                                individual believer)
                                [Pages
                                3-4
                                original ASML]</i></p>
                        <p>“The news of the very successful Congress held in Santiago pleased him very much. Now that
                            more of the Latin
                            believers are active and beginning to assume responsibilities, the work will go forward on a
                            more permanent
                            foundation, as pioneers from a foreign land can never take the place of native believers,
                            who must always
                            constitute the bedrock of any future development of the Faith in their country.”
                            <br/><i>(From letter dated January 30, 1 948, written on behalf of Shoghi Effendi to an
                                individual believer)
                                [Page 4 original ASML]</i></p>
                        <p>“. . . Great patience must be used in dealing with the childlike members of some of these
                            primitive races.
                            They are innocent in heart and have certainly had a very bad example, in many Christians, of
                            a purely
                            mercenary approach to religion; but if their hearts and minds once become illumined with the
                            Faith, they
                            could make very fine believers."
                            <br/><i>(From letter dated April 29, 1948, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly of
                                the British Isles) [Page 4 original ASML]</i></p>
                        <p>“We can truly say that this Cause is a Cause that enables people to achieve the impossible!
                            For the Bahá’ís,
                            everywhere, for the most part, are people with no great distinguishments of either wealth or
                            fame, and yet
                            once they make the effort and go forth in the name of Bahá’u’lláh to spread His Faith, they
                            become, each
                            one, as efficacious as a host! Witness what Mustafa Roumie accomplished in Burma, and a
                            handful of pioneers
                            achieved in a decade, in Latin America! It is the quality of devotion and self-sacrifice
                            that brings rewards
                            in the service of this Faith rather than means, ability or financial backing.”
                            <br/><i>(From letter dated May 11, 1948, written on behalf of Shoghi Effendi to the National
                                Spiritual
                                Assembly
                                of
                                Australia and New Zealand) [Page 4 original ASML]</i></p>
                        <p>“The work being done by various Bahá’ís, including our dear Indian believer who returned from
                            the United
                            States in order to pioneer amongst his own people, in teaching the Canadian Indians, is one
                            of the most
                            important fields of activity under your jurisdiction. The Guardian hopes that ere long many
                            of these
                            original Canadians will take an active part in Bahá’í affairs and arise to redeem their
                            brethren from the
                            obscurity and despondency into which they have fallen.”
                            <br/><i>(From letter dated June 23, 1950, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly
                                of
                                Canada) [Page 5 original ASML]</i></p>
                        <p>“Every outward thrust into new fields, every multiplication of Bahá’ís institutions, must be
                            paralleled by a
                            deeper thrust of the roots which sustain the spiritual life of the community and ensure its
                            sound
                            development. From this vital, this ever-present need, attention must, at no time, be
                            diverted, or nor must
                            it be, under any circumstances neglected, or subordinated to the no less vital and urgent
                            task of ensuring
                            the outer expansion of Bahá’ís administrative institutions. That this community . . . may
                            maintain a proper
                            balance between these two essential aspects of its development . . . is the ardent hope of
                            my heart . .
                            .”–Shoghi Effendi
                            <br/><i>(From letter dated December 30, 1948, to the National Spiritual Assembly of
                                Australia and New
                                Zealand)
                                Page 5
                                original ASML]</i></p>
                        <p>“Shoghi Effendi is also most anxious for the Message to reach the aboriginal inhabitants of
                            the Americas.
                            These people, for the most part down-trodden and ignorant, should receive from the Bahá’ís a
                            special measure
                            of love, and every effort be made to teach them. Their enrollment in the Faith will enrich
                            them and us, and
                            demonstrate our principle of the Oneness of Man far better than words or the wide conversion
                            of the ruling
                            races ever can.”
                            <br/><i>(From letter dated July 11, 1951, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly
                                of
                                Meso-America and the Antilles) Page 5 original ASML]</i></p>
                        <p>“Your Assembly is called upon to direct and safe-guard the activities of our Faith in a truly
                            vast and
                            impressive area. But the very newness of the work, the room for spiritual conquest, the
                            great need of the
                            people, both aboriginal and European in origin, to hear of Bahá’u’lláh, is stimulating and
                            challenging, and
                            must call forth the best in every believer.</p>
                        <p>“The Guardian feels that special efforts must be made to enroll the primitive peoples of
                            South America in the
                            Cause. These souls, often so exploited and despised, deserve to hear of the Faith, and will
                            become a great
                            asset to it once their hearts are enlightened.”
                            <br/><i>(From letter dated July 11, I 951, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly of
                                South America) [Pages 5-6 original ASML]</i></p>
                        <p> “He was very pleased to hear of initial steps you have taken to teach the Indians. He adds
                            one suggestion
                            (he does not know if it is practicable or not): can contact not be made with Indians who
                            have become more or
                            less absorbed into the life of the white element of the country and live in or visit the big
                            cities? These
                            people, finding the Bahá’ís sincerely lacking in either prejudice—or that even worse
                            attitude,
                            condescension—might not only take interest in our teachings, but also help us to reach their
                            people in the
                            proper </p>
                        <p>“It is a great mistake to believe that because people are illiterate or live primitive lives,
                            they are
                            lacking in either intelligence or sensibility. On the contrary, they may well look on us,
                            with the evils of
                            our civilization, with its moral corruption, its ruinous wars, its hypocrisy and conceit, as
                            people who
                            merit watching with both suspicion and contempt. We should meet them as equals,
                            well-wishers, people who
                            admire and respect their ancient descent, and who feel that they will be interested, as we
                            are, in a living
                            religion and not in the dead forms of present-day churches.”
                            <br/><i>(From letter dated September 21, l951, written on behalf of Shoghi Effendi to the
                                National Teaching
                                Committee
                                of the Americas) [Page 6 original]</i></p>
                        <p>“The Guardian was most happy to hear of the excellent work some of the Bahá’ís are doing with
                            the Eskimos and
                            the Indians and considers their spirit most exemplary. They are rendering a far greater
                            service than they,
                            themselves, are aware of, the fruits of which will be seen, not only in Canada, but because
                            of their
                            repercussions, in other countries where primitive populations must be taught.”
                            <br/><i>(From letter dated June 8, 1952, written on behalf of Shoghi Effendi to the National
                                Spiritual
                                Assembly
                                of
                                Canada) [Page 6 original ASML]</i></p>
                        <p>“He was particularly pleased to see that members of your Assembly have been out traveling and
                            contacting the
                            friends in an effort to deepen their understanding of the workings of the administration and
                            also their
                            knowledge of the Faith in general. He feels that particularly at present in Latin America
                            this intimate,
                            loving and friendly approach will do more to further the work than anything else. Indeed, he
                            would go so far
                            as to advise your Assembly to avoid deluging the friends with circulars and unnecessary
                            bulletins. You must
                            always bear in mind the genuine difference between the peoples of the south and the peoples
                            of the north; to
                            use the same techniques as those adopted in the United States would be disastrous because
                            the mentality and
                            background of life are quite different. Much as the friends need administration, it must be
                            brought to them
                            in a palatable form; otherwise they will not be able to assimilate it and instead of
                            consolidating the work
                            you will find some of the believers become estranged from it.</p>
                        <p>“Whenever you feel at all discouraged you should remember how many years it took for the
                            administration to
                            get as well established as it is at present in North America. Problems repeat themselves,
                            and in the earlier
                            stages in the U.S.A. the body of the believers was very loosely knit together, many of the
                            friends were, as
                            they now are in Latin America, affiliated with various more or less progressive cults [from]
                            which they had
                            come to the Faith and from which they could not be suddenly cut off; they had to be weaned
                            and educated; the
                            same thing you must now do. He urges you therefore to be very patient with the believers
                            and, through loving
                            consultation and education, gradually insist that the old allegiances must give way to the
                            great and
                            all-satisfying bond they have now found with Bahá’u’lláh and His Faith.</p>
                        <p>“Mature teachers are the greatest need of the Faith everywhere, and no doubt in your area
                            too. One wise and
                            dedicated soul can so often give life to an inactive community, bring in new people and
                            inspire them to
                            greater sacrifice. He hopes that whatever else you are able to do during the coming months,
                            you will be able
                            to keep in circulation a few really good Bahá’í teachers.”
                            <br/><i>(From letter dated June 30, 1952, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly
                                of
                                Central America) [Page 7 original ASML]</i></p>
                        <p>“He fully appreciates the fact that believers are still somewhat attached to the different
                            cults from which
                            they have co me; this is a problem which always faces the Faith in a new region; it existed
                            a long time in
                            America, and seems part of the growth of the Cause. He feels your Assembly can afford to be
                            patient with the
                            friends, while at the same time educating them into a deeper understanding of the Cause. As
                            their awareness
                            of the true significance of Bahá’u’lláh grows, they will become weaned from the old ideas
                            and give full
                            allegiance to His teachings.”
                            <br/><i>(From letter dated June 30, 1952, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly
                                of
                                South America) Pages 7-8 original ASML]</i></p>
                        <p>“Rejoice share Bahá’í communities East West thrilling reports feats achieved heroic band
                            Bahá’í pioneers
                            laboring divers widely scattered African territories . . . reminiscent alike episodes
                            related Book Acts
                            rapid dramatic propagation Faith instrumentality dawn-breakers Heroic Age Bahá’í
                            Dispensation. Marvelous
                            accomplishments signalizing rise establishment administrative order Faith Latin America
                            eclipsed. Exploits
                            immortalizing recently launched crusade European continent surpassed . . . Number Africans
                            converted course
                            last fifteen months . . . Protestant Catholic pagan backgrounds lettered unlettered both
                            sexes
                            representative no less sixteen tribes passed two hundred mark.</p>
                        <p>“Effulgent rays God’s triumphant Cause . . . fast awakening continent penetrating
                            accelerating rate isolated
                            regions unfrequented white men enveloping their radiance souls hitherto indifferent
                            persistent humanitarian
                            activities Christian missions civilizing influence civil authorities.” – Shoghi
                            <br/><i>(From cable dated January 5, 1953, to the National Spiritual Assembly of the United
                                States) Page 8
                                original
                                ASML]</i></p>
                        <p>“He was very happy indeed to learn of the very active manner in which the Canadian Bahá’ís
                            have taken hold of
                            this most important subject of teaching the Indians.</p>
                        <p>“He attaches the greatest importance to this matter as the Master has spoken of the latent
                            strength of
                            character of these people and feels that when the Spirit of the Faith has a chance to work
                            in their midst,
                            it will produce remarkable results.”</p>
                        <p>“I hail with a joyous heart the convocation in the heart of the African continent of the
                            first of the four
                            Intercontinental Teaching Conferences constituting the highlights of the worldwide
                            celebrations of the Holy
                            Year which commemorates the hundredth anniversary of the birth of the Mission of the Founder
                            of our Faith. I
                            welcome with open arms the unexpectedly large number of the representatives of the
                            pure-hearted and the
                            spiritually receptive Negro race, so dearly loved by ‘Abdu’l-Bahá, for whose conversion to
                            His Father’s
                            Faith He so deeply yearned and whose interests He so ardently championed in the course of
                            His memorable
                            visit to the North American continent. I am reminded, on this historic occasion, of the
                            significant words
                            uttered by Bahá’u’lláh Himself, Who as attested by the Center of the Covenant, in His
                            Writings, ‘compared
                            the colored people to the black pupil of the eye,’ through which ‘the light of the spirit
                            shineth forth.’ I
                            feel particularly gratified by the substantial participation in this epoch-making Conference
                            of the members
                            of a race dwelling in a continent which for the most part has retained its primitive
                            simplicity and remained
                            uncontaminated by the evils of a gross, a rampant and cancerous materialism undermining the
                            fabric of human
                            society alike in the East and in the West, eating into the vitals of the conflicting people
                            and races
                            inhabiting the American, the European and the Asiatic continents, and alas threatening to
                            engulf in one
                            common catastrophic convulsion the generality of mankind. I acclaim the preponderance of the
                            members of this
                            same race at so significant a Conference, a phenomenon unprecedented in the annals of Bahá’í
                            Conferences
                            held during over a century, and auguring well for a corresponding multiplication in the
                            number of the
                            representatives of the yellow, the red and brown races of mankind dwelling respectively in
                            the Far East, in
                            the Far West and in the islands of the South Pacific Ocean, a multiplication designed
                            ultimately to bring to
                            a proper equipoise the divers ethnic elements comprised within the highly diversified
                            world-embracing Bahá’í
                            Fellowship.”–Shoghi Effendi
                            <br/><i>(From message dated February 1953 to the African Intercontinental Conference) [Pages
                                8-9 original
                                ASML]</i></p>
                        <p>“He hopes that, during this coming year, you . . . will devote yourselves as much as you can
                            to consolidating
                            the new Assemblies (in Uganda) and assisting the new believers to gradually understand
                            better the
                            Administration, and its application in Bahá’í community life. Tact, love and patience will
                            no doubt be
                            needed, and one cannot expect these new believers to do everything in the same way that the
                            old and tried
                            communities do. Indeed, individuality of expression, within the framework of the
                            Administrative Order, is
                            preferable to too great a uniformity.”
                            <br/><i>(From letter dated April 26, 1953, written on behalf of Shoghi Effendi to an
                                individual believer)
                                [Page
                                9
                                original ASML]</i></p>
                        <p>“He was very happy to know that Charlottetown not only achieved Assembly status, but that the
                            believers there
                            are mostly self-supporting, as this is a sound basis for the expansion of the work in any
                            place, especially
                            in such a difficult one.”
                            <br/><i>(From letter dated June 20, 1953, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly
                                of
                                Canada) [Page 10 original ASML]</i></p>
                        <p>“He feels that a great potential strength lies in these new African believers. No doubt your
                            Committee will
                            be faced with problems, due to the inexperience of some of these people in administrative
                            matters, but,
                            through loving guidance, and the wisdom of those who are associated with them on the spot,
                            these minor
                            things can be satisfactorily taken care of, and the main thing, the establishment of
                            Assemblies and Groups,
                            be carried out successfully.”
                            <br/><i>(From letter dated June 4, 1953, written on behalf of Shoghi Effendi to the British
                                African
                                Committee)
                                [Page
                                10 original ASML]</i></p>
                        <p>“When enrolling new believers, we must be wise and gentle, and not place so many obstacles in
                            their way that
                            they feel it impossible to accept the Faith. On the other hand, once accorded membership in
                            the Community of
                            the followers of Bahá’u’lláh, it must be brought home to them that they are expected to live
                            up to His
                            Teachings, and to show forth the signs of a noble character in conformity with His Laws.
                            This can often be
                            done gradually, after the new believer is enrolled.”
                            <br/><i>(From letter dated June 25, 1953, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly
                                of
                                the British Isles) [Page 10 original ASML]</i></p>
                        <p>“It must, as it gathers momentum, awaken the select and gather the spiritually hungry amongst
                            the peoples of
                            the world, as well as create an awareness of the Faith not only among the political leaders
                            of present-day
                            society but also among the thoughtful, the erudite in other spheres of human activity. It
                            must, as it
                            approaches its climax, carry the torch of the Faith to regions so remote, so backward, so
                            inhospitable, that
                            neither the light of Christianity or Islám has, after the revolution of centuries, as yet
                            penetrated. It
                            must, as it approaches its conclusion, pave the way for the laying, on an unassailable
                            foundation, of the
                            structural basis of an Administrative Order whose fabric must, in the course of successive
                            Crusades, be
                            laboriously erected throughout the entire globe and which must assemble beneath its
                            sheltering shadow
                            peoples of every race, tongue, creed, color and nation.”–Shoghi Effendi
                            <br/><i>(From letter dated July 18, 1953, to the National Spiritual Assembly of the United
                                States) [Pages
                                10-11
                                original ASML]</i></p>
                        <p>“. . . Winning to the Faith fresh recruits to the slowly yet steadily advancing army of the
                            Lord of Hosts, .
                            . . is so essential to the safeguarding of the victories which the band of heroic Bahá’í
                            Conquerers are
                            winning in the course of their several campaigns in all the continents of the globe.</p>
                        <p>“Such a steady flow of reinforcements is absolutely vital and is of extreme urgency, for
                            nothing short of the
                            vitalizing influx of new blood that will reanimate the world Bahá’í Community can safeguard
                            the prizes
                            which, at so great a sacrifice involving the expenditure of so much time, effort and
                            treasure, are now being
                            won in virgin territories by Bahá’u’lláh's valiant Knights, whose privilege is to constitute
                            the spearhead
                            of the onrushing battalions which, in diverse theaters and in circumstances often adverse
                            and extremely
                            challenging, are vying with each other for the spiritual conquest of the unsurrendered
                            territories and
                            islands on the surface of the globe.</p>
                        <p>“This flow, moreover, will presage and hasten the advent of the day which, as prophesied by
                            ‘Abdu’l-Bahá,
                            will witness the entry by troops of peoples of divers nations and races into the Bahá’í
                            world—a day which,
                            viewed in its proper perspective, will be the prelude to that long-awaited hour when a mass
                            conversion on
                            the part of these same nations and races, and as a direct result of a chain of events,
                            momentous and
                            possibly catastrophic in nature, and which cannot as yet be even dimly visualized, will
                            suddenly
                            revolutionize the fortunes of the Faith, derange the equilibrium of the world, and reinforce
                            a thousandfold
                            the numerical strength as well as the material power and the spiritual authority of the
                            Faith of
                            Bahá’u’lláh.”–Shoghi Effendi
                            <br/><i>(From letter dated July 18, 1953, to the National Spiritual Assembly of the United
                                States) [Page 11
                                original
                                ASML]</i></p>
                        <p>“The beloved Guardian was greatly delighted to learn of the success of the institute for
                            teaching the Indian
                            children. He feels this is a very fine method of implanting the teachings of the Faith in
                            the hearts and the
                            minds of the young children, so that they may grow and develop into strong and virile men
                            and women who will
                            serve the Cause. Likewise through this effort, he hopes you will be able to attract some of
                            the
                            parents.”
                            <br/><i>(From letter dated February 18, 1954, written on behalf of Shoghi Effendi to an
                                individual believer)
                                [Page 12
                                original ASML]</i></p>
                        <p>“The spirit of the African believers is very touching, very noble, and indeed presents a
                            challenge to their
                            fellow-Bahá’ís all over the world. It seems that God has endowed these races, living in the
                            so-called ‘dark’
                            continent, with great spiritual faculties, and also with mental faculties, which, as they
                            mature in the
                            Faith, will contribute immensely to the whole, throughout the Bahá’í world . . .</p>
                        <p>“In your communications to the Bahá’ís of Uganda please assure them of the Guardian's
                            prayers, of his deep
                            affection for them, and tell them that he is proud of them, of their spirit and of their
                            achievements.”
                            <br/><i>(From letter dated May 11, 1954, written on behalf of Shoghi Effendi to the National
                                Spiritual
                                Assembly
                                of
                                Central and East Africa) [Page 12 original ASML]</i></p>
                        <p>“. . . The whole object of the pioneers in going forth to Africa, is to teach the colored
                            people, and not the
                            white people. This does not mean that they must refuse to teach the white people, which
                            would be a foolish
                            attitude. It does, however, mean that they should constantly bear in mind that it is to the
                            native African
                            that they are now carrying the Message of Bahá’u’lláh in his own country, and not to people
                            from abroad who
                            have migrated there permanently or temporarily and are a minority, and many of them, judging
                            by their acts,
                            a very unsavory minority.”
                            <br/><i>(From letter dated June 4, 1954, written on behalf of Shoghi Effendi to the British
                                African
                                Committee)
                                [Page
                                12 original ASML]</i></p>
                        <p>“Africa is truly awakening and finding herself, and she undoubtedly has a great message to
                            give and a great
                            contribution to make to the advancement of world civilization. To the degree to which her
                            peoples accept
                            Bahá’u’lláh, will they be blessed, strengthened and protected.”
                            <br/><i>(From letter dated June 4, 1954, written on behalf of Shoghi Effendi to the British
                                African
                                Committee)
                                [Pages
                                12-13 original ASML]</i></p>
                        <p>“In connection with the teaching work throughout the Pacific area, he fully believes that in
                            many cases the
                            white society is difficult to interest in anything but its own superficial activities. The
                            Bahá’ís must
                            identify themselves on the one hand, as much as they reasonably can, with the life of the
                            white people, so
                            as not to become ostracized, criticized and eventually ousted from their hard-won pioneer
                            posts. On the
                            other hand, they must bear in mind that the primary object of their living there is to teach
                            the native
                            population the Faith. This they must do with tact and discretion, in order not to forfeit
                            their foothold in
                            these islands which are often so difficult of access.</p>
                        <p>“Sound judgment, a great deal of patience and forbearance, faith and nobility of conduct,
                            must distinguish
                            the pioneers, and be their helpers in accomplishing the object of their journey to these far
                            places.”
                            <br/><i>(From letter dated June 16, 1954, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly
                                of
                                Australia and New Zealand) [Page 13 original ASML]</i></p>
                        <p>“In dealing with people who are still backward in relation to our civilized standards, and in
                            many cases
                            guided by a tribal system which has strong orders of its own, he feels that you should be
                            both tactful and
                            forbearing . . . We must not be too strict in enforcing our opinions on peoples still living
                            in primitive
                            social orders.”
                            <br/><i>(From letter dated June 17, 1954, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly
                                of
                                the British Isles) [Page 13 original ASML]</i></p>
                        <p>“He feels the teaching work in Uganda should now be concentrated on consolidation,
                            primarily.”
                            <br/><i>(From letter dated December 13, 1955, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly
                                of the British Isles) [Page 13 original ASML]</i></p>
                        <p>“Above all, the utmost endeavor should be exerted by your Assembly to familiarize the newly
                            enrolled
                            believers with the fundamental and spiritual verities of the Faith, and with the origins,
                            the aims and
                            purposes, as well as the processes of a divinely appointed Administrative Order, to acquaint
                            them more fully
                            with the history of the Faith, to instill in them a deeper understanding of the Covenants of
                            both
                            Bahá’u’lláh and of ‘Abdu’l-Bahá, to enrich their spiritual life, to rouse them to a greater
                            effort and a
                            closer participation in both the teaching of the Faith and the administration of its
                            activities, and to
                            inspire them to make the necessary sacrifices for the furtherance of its vital interests.
                            For as the body of
                            the avowed supporters of the Faith is enlarged, and the basis of the structure of its
                            Administrative Order
                            is broadened, and the fame of the rising community spreads far and wide, a parallel progress
                            must be
                            achieved, if the fruits already garnered are to endure, in the spiritual quickening of its
                            members and the
                            deepening of their inner life.” –Shoghi Effendi
                            <br/><i>(From letter dated June 26, 1956, to the National Spiritual Assembly of Canada)
                                [Page 14 original
                                ASML]</i></p>
                        <p>“Some of the territories are practically exclusively Arab with a European minority, with a
                            Muslim background
                            and an advanced civilization and culture of their own. They must be approached with teaching
                            methods
                            suitable for the mentality of the people. On the other hand, many of the other countries
                            represent a
                            backward people, from the standpoint of modern civilization, but people much more receptive
                            in heart and
                            soul to the Teachings of Bahá’u’lláh, much more sensitive to spiritual values, much readier
                            indeed to
                            embrace the Message of Bahá’u’lláh and arise in its service, as we have seen so wonderfully
                            demonstrated
                            during the last four years in the history of the Cause in Africa. These people must also be
                            taught in a way
                            that will attract them to the Cause.</p>
                        <p>“The African Bahá’ís already enrolled will require deepening in the Administration during the
                            coming years,
                            in order to better fit them for the day which must inevitably come when each protectorate,
                            country and
                            island will possess its own National Spiritual Assembly. To the degree to which your
                            Assembly succeeds in
                            laying a firm foundation at the present time, will depend the rapidity of the unfoldment of
                            the Faith in
                            these different countries and the readiness of the believers to shoulder independently their
                            national Bahá’í
                            work.</p>
                        <p>“As he has mentioned many times to pilgrims and in his communications to friends in North
                            Africa, he wishes
                            special attention paid in that area to the Berbers. Indeed every effort should be made to
                            enroll as many
                            Berber minorities in the Faith as possible, as well as the other races among the inhabitants
                            of the
                            country.”
                            <br/><i>(From letter dated July 2, 1956, written on behalf of Shoghi Effendi to the National
                                Spiritual
                                Assembly
                                of
                                North West Africa) [Pages 14-15 original ASML]</i></p>
                        <p>“The most important work of all is to train the African believers as teachers and
                            administrators, so that
                            they can carry the Message to their own people, and be in a position to lay firm and
                            enduring foundations
                            for the future Assemblies throughout all these vast territories. Every other activity falls
                            into
                            insignificance compared to this one.</p>
                        <p>“It is only natural that the help and advice of the pioneers should be required for some time
                            to come, owing
                            to their long experience in the Faith and their deeper knowledge of its Teachings, due to,
                            not only the
                            length of time they have been Bahá’ís and able to study them, but to the fact that so many
                            of the Teachings
                            are in English and they could study them.</p>
                        <p>“The translation of ever more literature into African tongues is likewise very important as
                            the new believers
                            are ready and eager to learn, and must have the facilities placed at their disposal.</p>
                        <p>“He hopes that your Assembly will be able to see that a stream of itinerant teachers and
                            pioneers constantly
                            goes out to the weak centers in the new territories, to consolidate the Faith and establish
                            new Assemblies,
                            to teach and encourage the people, and carry to them both the light and the love of
                            Bahá’u’lláh.”
                            <br/><i>(From letter dated July 4, 1956, written on behalf of Shoghi Effendi to the National
                                Spiritual
                                Assembly
                                of
                                South and West Africa) [Page 15 original ASML]</i></p>
                        <p>“I feel confident that your Assembly, now standing on the threshold of a period of
                            unparalleled expansion,
                            will, through its high endeavors, lend a tremendous impetus to the historic process which
                            has been set in
                            motion in recent years, and will, through its achievements, attract the manifold blessings
                            of
                            Bahá’u’lláh.</p>
                        <p>“The path you are now called upon to tread is long, steep and thorny. As the work in which
                            you are engaged
                            develops, and is steadily consolidated, individuals and institutions inimical to the Faith,
                            and jealous of
                            its rising prestige, will exert their utmost to undermine the foundations you are now laying
                            and to
                            extinguish the light which has been so brilliantly kindled by pioneers, settlers and newly
                            enrolled
                            believers throughout the territories now included within your jurisdiction.”–Shoghi Effendi
                            <br/><i>(From letter dated July 6, 1956, to the National Spiritual Assembly of Central and
                                East Africa)
                                [Pages
                                15-16
                                original ASML]</i></p>
                        <p>“The question of your budget, which you have raised in your letter, is one of great
                            importance. In spite of
                            the numbers which you represent and the enthusiasm of the Bahá’ís, your Assembly must face
                            the fact that it
                            represents a very poor Community, financially. Any over-ambitious budget, which would place
                            an oppressive
                            financial burden on the friends, would be highly unwise, because, unless it is met, it will
                            give them a
                            feeling at the end of the year of intense frustration.</p>
                        <p>“He thinks that what you have outlined is too much. Your Assembly will have to, particularly
                            during this
                            first year of its existence, be less ambitious as regards projects involving money, and
                            devote itself
                            particularly to encouraging the friends, reinforcing the foundations of the local
                            Assemblies, assisting the
                            Groups to attain Assembly status, and deepening in every way it can the education of the
                            African friends in
                            the Faith.</p>
                        <p>“The other National Spiritual Assemblies, as you know, are having their own problems
                            financially; and,
                            although there is no objection to appealing to them to give you some help, the Guardian
                            doubts very much
                            whether they will be in a position to add very substantially to your funds at this time.”
                            <br/><i>(From letter dated July 6, 1956, written on behalf of Shoghi Effendi to the National
                                Spiritual
                                Assembly
                                of
                                Central and East Africa) [Page 16 original ASML]</i></p>
                        <p>“The Beloved Guardian was very happy to review the map, with your notations thereon, showing
                            the number of
                            Indian Bahá’ís. He feels this is a real victory for the Faith, as the Master has spoken so
                            often of the
                            strength of character and latent capacity of the original peoples of the American continent.
                            Thus, the
                            quickening of some of them is a historic turning point in the activity of the Faith, as well
                            as the life of
                            these people.”
                            <br/><i>(From letter dated July 31, 1956, written on behalf of Shoghi Effendi to an
                                individual believer)
                                [Page
                                16
                                original ASML]</i></p>
                        <p>“He feels the Persians can render the utmost assistance to the teaching work, wherever they
                            settle; but they
                            must go on the basis of pioneers, and take up residence where they can render the best
                            service to the Cause
                            of God. It does little good for the Faith to have large groups of Persians settled in a
                            city, and thus
                            constitute an Assembly. When they move the Assembly falls. What we need in all areas is
                            native believers.
                            The pioneers should be in the minority, and aid the natives to shoulder the responsibilities
                            of the
                            Faith·”
                            <br/><i>(From letter dated February 17, 1957, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly
                                of Persia) [Page 17 original ASML]</i>
                            <p>“To stimulate the process of the conversion of both the Negroes and the American Indians,
                                and ensure
                                their
                                active participation in the administration of the affairs of Bahá’í communities; . . .”
                                –Shoghi Effendi
                                <br/><i>(From Convention Message dated April 1957 to the Four American Bahá’í
                                    Conventions) [Page 17
                                    original
                                    ASML]</i></p>
                            <p>“As you formulate your plans and carry them out for the work entrusted to you during the
                                next six years,
                                he
                                wishes you to particularly bear in mind the need of teaching the Maoris. These original
                                discoverers of
                                New
                                Zealand are of a very fine race, and they are a people admired for their noble
                                qualities; and special
                                effort
                                should be made, not only to contact the Maoris in the cities, and draw them into the
                                Faith, but to go to
                                their towns and live amongst them and establish Assemblies in which at least the
                                majority of the
                                believers
                                will be Maoris, if not all. This would be indeed a worthy achievement.”
                                <br/><i>(From letter dated June 27, I 957, written on behalf of Shoghi Effendi to the
                                    National Spiritual
                                    Assembly of
                                    New Zealand) [Page 17 original ASML]</i></p>
                            <p>“He hopes there will be a great deal more teaching activity during the present year, and
                                that the Latin
                                American Bahá’ís will increasingly feel that this is their Faith, and consequently their
                                obligation,
                                primarily, to spread it amongst their own people. Great as are the services rendered by
                                pioneers, and
                                unforgettable as are the deeds they accomplish, they cannot take the places of the
                                indigenous element
                                which
                                must constitute the bedrock of the Community, carry on its own affairs, build its own
                                institutions,
                                support
                                its own funds, publish its own literature, etc. A mother gives birth to a child, but the
                                child then has
                                to
                                grow for itself. The older it gets, the more responsible it is for its own acts. The
                                Latin American
                                friends
                                are rapidly coming of age, and they are showing this by the manner in which they are
                                arising to serve
                                the
                                Faith, to demonstrate it, to sacrifice for it, to protect it and consolidate it.”
                                <br/><i>(From letter dated July 3, 1957, written on behalf of Shoghi Effendi to the
                                    National Spiritual
                                    Assembly
                                    of
                                    Brazil, Peru, Colombia, Ecuador and Venezuela) [Pages 17-18 original ASML]</i></p>
                            <p>“As the situation in the world, and in your part of it (South and West Africa) is
                                steadily worsening, no
                                time
                                can be lost by the friends in rising to higher levels of devotion and service, and
                                particularly of
                                spiritual
                                awareness. It is our duty to redeem as many of our fellowmen as we possibly can, whose
                                hearts are
                                enlightened, before some great catastrophe overtakes them, in which they will either be
                                hopelessly
                                swallowed
                                up or come out purified and strengthened, and ready to serve. The more believers there
                                are to stand
                                forth as
                                beacons in the darkness whenever that time does come, the better; hence the supreme
                                importance of the
                                teaching work at this time . . .</p>
                            <p>“As he has written the Central and East Africa Assembly, he feels that the friends should
                                be very careful
                                not
                                to place hindrances in the way of those who wish to accept the Faith. If we make the
                                requirements too
                                rigorous, we will cool off the initial enthusiasm, rebuff the hearts and cease to expand
                                rapidly. The
                                essential thing is that the candidate for enrollment should believe in his heart in the
                                truth of
                                Bahá’u’lláh. Whether he is literate or illiterate, informed of all the Teachings or not,
                                is beside the
                                point
                                entirely. When the spark of faith exists the essential Message is there, and gradually
                                everything else
                                can
                                be added unto it. The process of educating people of different customs and backgrounds
                                must be done with
                                the
                                greatest patience and understanding, and rules and regulations not imposed upon them,
                                except where a
                                rock-bottom essential is in question. He feels sure that your Assembly is capable of
                                carrying on its
                                work in
                                this spirit, and of fanning the hearts to flame through the fire of the love of God,
                                rather than putting
                                out
                                the first sparks with buckets-full of administrative information and regulations.”
                                <br/><i>(From letter dated July 9, 1957, written on behalf of Shoghi Effendi to the
                                    National Spiritual
                                    Assembly of
                                    South and West Africa) [Page 18 original ASML]</i></p>
                            <p>“He feels that the goals you have set yourself for your Seven Year Plan are excellent.
                                The most important
                                work of all of course is to confirm as many new believers as possible, wherever and
                                whoever they maybe,
                                with
                                special attention given to the Africans, who are the very purpose of the pioneers’
                                presence in that
                                Continent.”</p>
                            <br/><i>(From letter dated July 14, 1957, written on behalf of Shoghi Effendi to the
                                National Spiritual
                                Assembly
                                of
                                North West Africa) [Page 19 original ASML]</i><br/>
                            <p>“It is not enough to bring people into the Faith; one must educate them and deepen their
                                love for it and
                                their knowledge of its teachings, after they declare themselves. As the Bahá’ís are few
                                in number,
                                especially the active teachers, and there is a great deal of work to be done, the
                                education of these new
                                believers is often sadly neglected, and then results are seen such as the resignations
                                you have had
                                recently.”
                                <br/><i>(From letter dated July 18, 1957, written on behalf of Shoghi Effendi to the
                                    National Spiritual
                                    Assembly of
                                    Canada) [Page 19 original ASML]</i></p>
                            <p>“The paramount task is, of course, the teaching work; at every session your assembly
                                should give it close
                                attention, considering everything else of secondary importance. Not only must many new
                                assemblies be
                                developed, as well as groups and isolated centers, but special attention must be focused
                                on the work of
                                converting the Indians to the Faith. The goal should be all-Indian assemblies, so that
                                these much
                                exploited
                                and suppressed original inhabitants of the land may realize that they are equals and
                                partners in the
                                affairs
                                of the Cause of God, and that Bahá’u’lláh is the Manifestation of God for them.</p>
                            <p>“The all-important enterprise, aimed at winning the whole­hearted allegiance of the
                                members of various
                                tribes
                                of the American Indians to the Cause of Bahá’u’lláh, and at ensuring their active and
                                sustained
                                participation in the conduct of its administrative affairs, must, likewise be seriously
                                considered and
                                strenuously pursued."
                                <br/><i>(From letter dated July 28, 1957, written on behalf of Shoghi Effendi to the
                                    National Spiritual
                                    Assembly of
                                    Central America and Mexico) [Page 19 original ASML]</i></p>
                            <p>“He was particularly happy to see that some of the Indian believers were present at the
                                Convention. He
                                attaches the greatest importance to teaching the original inhabitants of the Americas
                                the Faith.
                                ‘Abdu’l-Bahá Himself has stated how great are their potentialities, and it is their
                                right, and the duty
                                of
                                the non-Indian Bahá’ís, to see that they receive the Message of God for this day. One of
                                the most worthy
                                objectives of your Assembly must be the establishment of all-Indian Spiritual
                                Assemblies. Other
                                minorities
                                should likewise be especially sought out and taught. The friends should bear in mind
                                that in our Faith,
                                unlike every other society, the minority, to compensate for what might be treated as an
                                inferior status,
                                receives special attention, love and consideration . . .</p>
                            <p>“As you formulate the goals which must receive your undivided attention during the coming
                                years he urges
                                you
                                to bear in mind the most important one of all, namely the multiplication of the
                                Spiritual Assemblies,
                                the
                                groups and the isolated centers; this will ensure both breadth and depth in the
                                foundations you are
                                laying
                                for the future independent National Bodies. The believers should be urged to consider
                                individually the
                                needs
                                in their immediate region, and to go forth to pioneer in near and distant cities and
                                towns. They must be
                                encouraged by your Assembly to remember that small people, often poor and obscure
                                people, have changed
                                the
                                course of human destiny more than people who started out with wealth, fame and security.
                                It was the
                                Sifter
                                of Wheat who, in the early days of our Faith, arose and became a hero and martyr, not
                                the learned
                                priests of
                                his city!”
                                <br/><i>(From letter dated July 1957 written on behalf of Shoghi Effendi to one of the
                                    National
                                    Spiritual
                                    Assemblies
                                    of Latin America) [Pages 19-20 original ASML]</i></p>
                            <p>“He feels that those responsible for accepting new believers should consider that the
                                most important and
                                fundamental qualification for acceptance is the recognition of the station of
                                Bahá’u’lláh in this day on
                                the
                                part of the applicant. We cannot expect people who are illiterate (which is no
                                reflection on their
                                mental
                                abilities or capacities) to have studied the Teachings, especially when so little
                                literature is
                                available in
                                their own language in the first place, and grasp all their ramifications, the way an
                                African, say in
                                London,
                                is expected to. The spirit of the person is the important thing, the recognition of
                                Bahá’u’lláh and His
                                position in the world in this day. The friends therefore must not be too strict, or they
                                will find that
                                the
                                great wave of loving enthusiasm with which the African people have turned to the Faith,
                                many of them
                                already
                                accepting it, cools off; and being very sensitive, they will feel in some subtle way
                                that they are
                                rebuffed,
                                and the work will suffer.</p>
                            <p>“The purpose of the new National Assemblies in Africa, and the purpose of any
                                administrative body, is to
                                carry the Message to the people and enlist the sincere under the banner of this
                                Faith.</p>
                            <p>“Your Assembly must never lose sight of this for a moment, and must go on courageously
                                expanding the
                                membership of the communities under your jurisdiction, and gradually educating the
                                friends in both the
                                Teachings and the Administration. Nothing could be more tragic than if the establishment
                                of these great
                                administrative bodies should stifle or bog down the teaching work. The early believers
                                in both the east
                                and
                                the west, we must always remember, knew practically nothing compared to what the average
                                Bahá’í knows
                                about
                                his Faith nowadays, yet they were the ones who shed their blood, the ones who arose and
                                said: ‘I
                                believe,’
                                requiring no proof, and often never having read a single word of the Teachings.
                                Therefore, those
                                responsible
                                for accepting new enrollments must just be sure of one thing—that the heart of the
                                applicant has been
                                touched with the spirit of the Faith. Everything else can be built on this foundation
                                gradually.</p>
                            <p>“He hopes that during the coming year it will be increasingly possible for the African
                                Bahá’í teachers to
                                circulate amongst the newly-enrolled Bahá’ís and deepen their knowledge and
                                understanding of the
                                Teachings.”
                                <br/><i>(From letter dated August 8, 1957, written on behalf of Shoghi Effendi to the
                                    National Spiritual
                                    Assembly of
                                    Central and East Africa) [Pages 20-21 original ASML]</i></p>
                            <p>“First and foremost, the vital process of the conversion of the Africans must acquire a
                                momentum which
                                will
                                surpass any hitherto witnessed in African Bahá’í history. Any barrier impeding the
                                discharge of this
                                preeminent duty must be determinedly swept aside. Simultaneously, the emergence of new
                                centers, the
                                conversion of groups into Assemblies and the multiplication of the Assemblies
                                themselves, must be
                                accelerated to an unprecedented extent . . . The institution of the National Fund, so
                                vital and
                                essential
                                for the uninterrupted progress of these activities must, in particular, be assured of
                                the whole-hearted,
                                the
                                ever-increasing and universal support of the mass of believers, for whose welfare, and
                                in whose name,
                                these
                                beneficent activities have been initiated and have been conducted. All, no matter how
                                modest their
                                resources, must participate. Upon the degree of self-sacrifice involved in these
                                individual
                                contributions
                                will directly depend the efficacy and the spiritual influence which these nascent
                                administrative
                                institutions, called into being through the power of Bahá’u’lláh, and by virtue of the
                                Design conceived
                                by
                                the Center of His Covenant, will exert. A sustained and strenuous effort must henceforth
                                be made by the
                                rank
                                and file of the avowed upholders of the Faith, whose number far surpasses that of their
                                brethren
                                residing in
                                the areas administered by the three other Regional Spiritual Assemblies, to enable the
                                communities under
                                your jurisdiction to become self-supporting and ensure a steady flow of funds to the
                                Treasury which now
                                stands in dire need of substantial financial support.”–Shoghi Effendi
                                <br/><i>(From letter dated August 8, 1957, to the National Spiritual Assembly of Central
                                    and East
                                    Africa) [Pages
                                    21-22 original ASML]</i></p>
                            <p>“The Master has likened the Indians in your Countries to the early Arabian Nomads at the
                                time of the
                                appearance of Muhammad. Within a short period of time they became the outstanding
                                examples of education,
                                of
                                culture and of civilization for the entire world. The Master feels that similar wonders
                                will occur today
                                if
                                the Indians are properly taught and if the power of the Spirit properly enters into
                                their living.”
                                <br/><i>(From letter dated August 22, 1957, written on behalf of Shoghi Effendi to the
                                    National-Spiritual Assembly of
                                    Central America and Mexico) [Page 22 original ASML]</i></p>
                            <p>“He was very happy indeed to learn of the very active manner in which the Canadian
                                Bahá’ís have taken
                                hold of
                                this most important subject of teaching the Indians.</p>
                            <p>“He attaches the greatest importance to this matter as the Master has spoken of the
                                latent strength of
                                character of these people and feels that when the Spirit of the Faith has a chance to
                                work in their
                                midst,
                                it will produce remarkable results.”
                                <br/><i>(From letter dated October 19, 1957, written on behalf of Shoghi Effendi to the
                                    National
                                    Spiritual Assembly of Canada) [Page 22 original ASML]</i></p>
                        </p>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <center>
                        <h4>Messages from the Universal House of Justice</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <p>“When the masses of mankind are awakened and enter the Faith of God, a new process is set in
                            motion and the
                            growth of a new civilization begins. Witness the emergence of Christianity and of Islám.
                            These masses are
                            the rank and file, steeped in traditions of their own, but receptive to the new Word of God,
                            by which, when
                            they truly respond to it, they become so influenced as to transform those who come in
                            contact with them.
                            God's standards are different from those of men. According to men’s standards, the
                            acceptance of any cause
                            by people of distinction, of recognized fame and status, determines the value and greatness
                            of that cause.
                            But, in the words of Bahá’u’lláh: “The summons and Message which We gave were never intended
                            to reach or to
                            benefit one land or one people only. Mankind in its entirety must firmly adhere to
                            whatsoever has been
                            revealed and vouchsafed unto it.” Or again, “He has endowed every soul with the capacity to
                            recognize the
                            signs of God. How could He, otherwise, have fulfilled His testimony unto men, if ye be of
                            them that ponder
                            His Cause in their hearts.” In countries where teaching the masses has succeeded, the
                            Bahá’ís have poured
                            out their time and effort in village areas to the same extent as they had formerly done in
                            cities and towns.
                            The results indicate how unwise it is to solely concentrate on one section of the
                            population. Each National
                            Assembly therefore should so balance its resources and harmonize its efforts that the Faith
                            of God is taught
                            not only to those who are readily accessible but to all sections of society, however remote
                            they may be.
                            The unsophisticated people of the world—and they form the large majority of its
                            population—have the same
                            right to know of the Cause of God as others. When the friends are teaching the Word of God
                            they should be
                            careful to give the Message in the same simplicity as it is enunciated in our Teachings. In
                            their contacts
                            they must show genuine and divine love. The heart of an unlettered soul is extremely
                            sensitive; any trace of
                            prejudice on the part of the pioneer or teacher is immediately sensed when teaching among
                            the masses, the
                            friends should be careful not to emphasize the charitable and humanitarian aspects of the
                            Faith as a means
                            to win recruits. Experience has shown that when facilities such as schools, dispensaries,
                            hospitals, or even
                            clothes and food are offered to the people being taught, many complications arise. The prime
                            motive should
                            always be the response of man to God’s message, and the recognition of His Messenger. Those
                            who declare
                            themselves as Bahá’ís should become enchanted with the beauty of the Teachings, and touched
                            by the love of
                            Bahá’u’lláh. The declarants need not know all the proofs, history, laws, and principles of
                            the Faith, but in
                            the process of declaring themselves they must, in addition to catching the spark of faith,
                            become basically
                            informed about the Central Figures of the Faith, as well as the existence of laws they must
                            follow and an
                            administration they must obey.</p>
                        <p>After declaration, the new believers must not be left to their own. Through correspondence
                            and dispatch of
                            visitors, through conferences and training courses, these friends must be patiently
                            strengthened and
                            lovingly helped to develop into full Bahá’í maturity. The beloved Guardian referring to the
                            duties of Bahá’í
                            Assemblies in assisting the newly declared believer has written:“. . . the members of each
                            and every
                            Assembly should endeavor, by their patience, their love, their tact and wisdom, to nurse,
                            subsequent to his
                            admission, the newcomer into Bahá’í maturity, and win him over gradually to the unreserved
                            acceptance of
                            whatever has been ordained in the Teachings.”</p>
                        <p>Expansion and consolidation are twin processes that must go hand in hand. The friends must
                            not stop
                            expansion in the name of consolidation. Deepening the newly-enrolled believers generates
                            tremendous stimulus
                            which results in further expansion. The enrollment of new believers, on the other hand,
                            creates a new spirit
                            in the community and provides additional potential manpower that will reinforce the
                            consolidation work.
                            We would like to share with you some of the methods used by National Assemblies in various
                            continents that
                            have proved useful in teaching the masses, and attach a list. Certain of these may be
                            valuable in your area,
                            in addition to any methods you may yourself devise.</p>
                        <p>We are fervently praying that all National and Local Spiritual Assemblies, supported by the
                            individual
                            believers, will achieve outstanding success in the fulfillment of this glorious objective.
                            –The Universal House of Justice.
                            <br/><i>(Extract from message dated July 13, 1964, to all National Spiritual Assemblies,
                                published in
                                Wellspring of Guidance, pp. 31-36) [Pages 25-27 original ASML]</i></p>

                        <h2>Teaching the Masses—Annex </h2>
                        <ol>
                            <li>Materials are sent at once to the new believers. In some places this material is in the
                                form of printed
                                cards, mainly in color, portraying a Bahá’í theme or principle. This helps the new
                                believer to know that
                                his declaration has been accepted and to feel that he now belongs to the new Faith.
                            </li>
                            <li>Training courses of about 2 weeks duration are held. To facilitate attendance and reduce
                                cost, a number
                                of villages are grouped together as one zone in which the course is held. The students
                                to the courses
                                are usually selected, so that the more capable participate, and teaching is facilitated.
                                Transportation
                                expenses, feeding and accommodation are provided, if it is found that the participants
                                are unable to
                                cover such expenses themselves. The material to be taught is prepared ahead of time,
                                presented in simple
                                language, and translated into the vernacular. After the course, the more promising
                                students are picked
                                out, and with their consent, are requested to undertake teaching projects for a limited
                                period. It is
                                sometimes found that long-term projects are also useful. These projects generally are
                                carefully planned
                                as to their du ration, places to be visited, and material to be taught. If the traveling
                                teachers are
                                not able to cover their expenses, traveling and living expenses are provided by the Fund
                                for the
                                execution of a given and temporary teaching project.
                            </li>
                            <li>Shorter training courses in the form of conferences over a long weekend are held.</li>
                            <li>These activities—training courses and conferences—are repeated as frequently as possible
                                and are not
                                dependent upon the acquisition of Teaching Institutes. In the absence of such
                                Institutes, these courses
                                and conferences are normally held in Bahá’í homes or hired quarters, such as schools,
                                etc. In order to
                                facilitate the physical catering and accommodation of the participants they are
                                sometimes asked to come
                                to the course with their eating utensils and bedding.
                            </li>
                            <li>In the visits made to the villages, the visiting teacher meets with the Local
                                Communities to give them
                                basic Bahá’í knowledge, such as living the Bahá’í life, the importance of teaching,
                                prayer, fasting,
                                Nineteen Day Feasts, Bahá’í elections, and contributions to the Fund. The question of
                                contributions to
                                the Fund is of utmost importance, so that the new believers may quickly feel themselves
                                to be
                                responsible members of the Community. Each National Assembly must find ways and means to
                                stimulate the
                                offering of contributions, in cash or kind, to make it easy for the friends to
                                contribute and to give
                                proper receipts to the donors.
                            </li>
                            <p>These are but suggestions based on experience which may help you in your efforts to teach
                                and deepen the
                                spiritually starved multitudes in your area.</p>
                            <p>In the course of carrying out such a tremendous spiritual campaign among the masses,
                                disappointments will
                                well be encountered. We tabulate a few instances that have been brought to our notice:
                                <ol type="a">
                                    <li>Visiting pioneers or teachers may find, in some places newly-enrolled believers
                                        not so
                                        enthusiastic about their religion as expected, or not adjusting to standards of
                                        Bahá’í life, or
                                        they may find them thinking of material benefits they may hope to derive from
                                        their new
                                        membership. We should always remember that the process of nursing the believer
                                        into full
                                        spiritual ma turity is slow, and needs loving education and patience.
                                    </li>
                                    <li>Some teaching committees, in their eagerness to obtain results, place undue
                                        emphasis on
                                        obtaining a great number of declarations to the detriment of the quality of
                                        teaching.
                                    </li>
                                    <li>Some traveling teachers, in their desire to show the result of their services,
                                        may not
                                        scrupulously teach their contacts, and in some rare cases, if, God forbid, they
                                        are insincere,
                                        may even give false reports.
                                        <p>Such irregularities have happened and can be repeated, but must not be a
                                            source of
                                            discouragement. By sending a team of teachers to an area, or by sending at
                                            intervals other
                                            teachers to those areas, and through correspondence and reports, such
                                            situations can be
                                            detected and immediately adjusted. The administration of the Faith must at
                                            all times keep in
                                            close touch with the teaching work.</p>
                                    </li>
                                </ol>
                            </p>

                            <p>To sum up:
                                <ol>
                                    <li>Teaching the waiting masses is a reality facing each National Assembly.</li>
                                    <li>The friends must teach with conviction, determination, genuine love, lack of
                                        prejudice, and in a
                                        simple language addressed to the heart.
                                    </li>
                                    <li>Teaching must be followed up by training courses, conferences, and regular
                                        visits to deepen the
                                        believers in their knowledge of the Teachings.
                                    </li>
                                    <li>The close touch of the National Office or Teaching Commit tees with the work is
                                        most essential,
                                        so
                                        that through reports and correspondence not only is information obtained and
                                        verified, but
                                        stimulation and encouragement is given.
                                    </li>
                                    <li>Expansion and consolidation go hand in hand.</li>
                                </ol>
                            </p>

                            <h2>EXPANSION AND CONSOLIDATION AMONG THE MASSES</h2>

                            <p>Since writing to the National Spiritual Assemblies of the world regarding the importance
                                of teaching the
                                masses, we have received reports from all over the world indicating the steady increase
                                in the number of
                                believers, the concentration of the friends on the more receptive areas, however remote
                                these may have
                                been,
                                and the opening up of new and challenging fields for expansion and service. In this
                                letter we wish once
                                again to stress the importance of this subject, share with you our thoughts regarding
                                the supreme need
                                to
                                preserve the victories you have already won and the necessity to pursue the vital work
                                in which you are
                                engaged and to which the eyes of your sister communities in East and West are turned
                                with
                                admiration.</p>
                            <p>It has been due to the splendid victories in large-scale conversion that the Faith of
                                Bahá’u’lláh has
                                entered
                                a new phase in its development and establishment throughout the world. It is imperative,
                                therefore, that
                                the
                                process of teaching the masses be not only maintained but accelerated. The teaching
                                committee structure
                                that
                                each National Assembly may adopt to ensure best results in the extension of its teaching
                                work is a
                                matter
                                left entirely to its discretion, but an efficient teaching structure there must be, so
                                that the tasks
                                are
                                carried out with dispatch and in accordance with the administrative principles of our
                                Faith. From among
                                the
                                believers native to each country, competent traveling teachers must be selected and
                                teaching projects
                                worked
                                out. In the words of our beloved Guardian, commenting upon the teaching work in Latin
                                America: “Strong
                                and
                                sustained support should be given to the vitally needed and meritorious activities
                                started by the native
                                . .
                                . traveling teachers, . . . who, as the mighty task progresses, must increasingly bear
                                the brunt of
                                responsibility for the propagation of the Faith in their homelands.”</p>
                            <p>While this vital teaching work is progressing each National Assembly must ever bear in
                                mind that
                                expansion
                                and consolidation are inseparable processes that must go hand in hand. The
                                inter­dependence of these
                                processes is best elucidated in the following passage from the writings of the beloved
                                Guardian: “Every
                                outward thrust into new fields, every multiplication of Bahá’í institutions, must be
                                paralleled by a
                                deeper
                                thrust of the roots which sustain the spiritual life of the community and ensure its
                                sound development.
                                From
                                this vital, this ever-present need, attention must, at no time, be diverted, nor must it
                                be, under any
                                circumstances neglected, or subordinated to the no less vital and urgent task of
                                ensuring the outer
                                expansion of Bahá’í administrative institutions. That this community . . . may maintain
                                a proper balance
                                between these two essential aspects of its development . . . is the ardent hope of my
                                heart.” To ensure
                                that
                                the spiritual life of the individual believer is continuously enriched, that local
                                communities are
                                becoming
                                increasingly conscious of their collective duties, and that the institutions of an
                                evolving
                                administration
                                are operating efficiently, is, therefore, as important as expanding into new fields and
                                bringing in the
                                multitudes under the shadow of the Cause.</p>
                            <p>These objectives can only be attained when each National Spiritual Assembly makes proper
                                arrangements for
                                all
                                the friends to be deepened in the knowledge of the Faith. The National Spiritual
                                Assemblies in
                                consultation
                                with the Hands of the Cause, who are the Standard-Bearers of the Nine Year Plan, should
                                avail themselves
                                of
                                the assistance of Auxiliary Board Members, who, together with the traveling teachers
                                selected by the
                                Assembly or its Teaching Committees, should be continuously encouraged to conduct
                                deepening courses at
                                Teaching Institutes and to make regular visits to local Spiritual Assemblies. The
                                visitors, whether
                                Board
                                Members or traveling teachers should meet on such occasions not only with the local
                                Assembly but, of
                                course,
                                with the local community members, collectively at general meetings and even, if
                                necessary, individually
                                in
                                their homes.</p>
                            <p>The subjects to be discussed at such meetings with the local Assembly and the friends
                                should include
                                among
                                others the following points:
                                <ol>
                                    <li>the extent of the spread and stature of the Faith today;</li>
                                    <li>the importance of the daily obligatory prayers (at least the short prayer);</li>
                                    <li>the need to educate Bahá’í children in the Teachings of the Faith and encourage
                                        them to memorize
                                        some of the prayers;
                                    </li>
                                    <li>the stimulation of youth to participate in community life by giving talks, etc.
                                        and having their
                                        own
                                        activities, if possible;
                                    </li>
                                    <li>the necessity to abide by the laws of marriage, namely, the need to have a
                                        Bahá’í ceremony, to
                                        obtain the consent of parents, to observe monogamy; faithfulness after marriage;
                                        likewise the
                                        importance of abstinence from all intoxicating drinks and drugs;
                                    </li>
                                    <li>the local Fund and the need for the friends to understand that the voluntary act
                                        of contributing
                                        to
                                        the Fund is both a privilege and a spiritual obligation. There should also be
                                        discussion of
                                        various
                                        methods that could be followed by the friends to facilitate their contributions
                                        and the ways
                                        open to
                                        the local Assembly to utilize its local Fund to serve the interests of its
                                        community and the
                                        Cause;
                                    </li>
                                    <li>the importance of the Nineteen-Day Feast and the fact that it should be a joyful
                                        occasion and
                                        rallying point of the entire community;
                                    </li>
                                    <li>the manner of election with as many workshops as required, including teaching of
                                        simple methods
                                        of
                                        balloting for illiterates, such as having one central home as the place for
                                        balloting and
                                        arranging
                                        for one literate person, if only a child, to be present at that home during the
                                        whole day, if
                                        necessary;
                                    </li>
                                    <li>last but not least, the all-important teaching work, both in the locality and
                                        its neighboring
                                        centers, as well as the need to continuously deepen the friends in the
                                        essentials of the Faith.
                                        The
                                        friends should be made to realize that in teaching the Faith to others they
                                        should not only aim
                                        at
                                        assisting the seeking soul to join the Faith, but also at making him a teacher
                                        of the Faith and
                                        its
                                        active supporter.
                                    </li>
                                </ol>
                            </p>
                        </ol>
                        <p>All the above points should, of course, be stressed within the framework of the importance of
                            the local
                            Spiritual Assembly, which should be encouraged to vigorously direct its attention to these
                            vital functions
                            and become the very heart of the community life of its own locality, even if its meetings
                            should become
                            burdened with the problems of the community. The local friends should understand the
                            importance of the law
                            of consultation and realize that it is to the local Spiritual Assembly that they should tum,
                            abide by its
                            decisions, support its projects, cooperate whole heartedly with it in its task to promote
                            the interests of
                            the Cause, and seek its advice and guidance in the solution of personal problems and the
                            adjudication of
                            disputes, should any arise amongst the members of the community. –The Universal House of
                            Justice
                            <br/><i>(Message dated February 2, 1966, to all National Spiritual Assemblies engaged in
                                mass teaching work)
                                [Pages 27-32 original ASML]</i></p>

                        <h2>WIDENING THE SCOPE OF ENDEAVORS</h2>
                        <p>We note that the new teaching methods you have developed, in reaching the waiting masses,
                            have substantially
                            influenced the winning of your goals, and we urge the American Bahá’ís, one and all, newly
                            enrolled and
                            believers of long standing, to arise, put their reliance in Bahá’u’lláh and armed with that
                            supreme power,
                            continue unabated their efforts to reach the waiting souls, while simultaneously
                            consolidating the hard-won
                            victories. New methods inevitably bring with them criticism and challenges no matter how
                            successful they may
                            ultimately prove to be. The influx of so many new believers is, in itself, a call to the
                            veteran believers
                            to join the ranks of those in this field of service and to give wholeheartedly of their
                            knowledge and
                            experience. Far from standing aloof, the American believers are called upon now, as never
                            before, to grasp
                            this golden opportunity which has been presented to them, to consult together prayerfully
                            and widen the
                            scope of their endeavors. –The Universal House of Justice
                            <br/><i>(Message dated February 2, 1966, to all National Spiritual Assemblies engaged in
                                mass teaching work)
                                [Pages 27-32 original ASML]</i></p>                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>


    </div>
)

import React  from 'react';

import {SoundCloudPlayer} from './SoundCloudPlayer';
import {YouTubePlayer} from "./YouTubePlayer";
import {Accordion}  from "react-bootstrap";

// @ts-ignore
export const IBC2022 = () => (
            <div>
                <p/>
                <h2>Indigenous Bahá'í Ceremony</h2>
                <Accordion defaultActiveKey="100">
                    <Accordion.Item eventKey="27">
                        <Accordion.Header>
                            <center>
                                <h4>Mary Gubatayao - December. 2022</h4>
                                <h4>(click here to access video/audio)</h4>
                                <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony Flyer 2022_12.jpg"} alt={"Roderick Harris"} width={"45%"} height={"45%"} />
                            </center>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1451675077%3Fsecret_token%3Ds-16fcd6nt9Tb&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                                <YouTubePlayer url="https://www.youtube.com/embed/8rJT7ICFFPs" />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="26">
                        <Accordion.Header>
                            <center>
                                <h4>Arthur Fernandez Scarberry - Choctaw</h4>
                                <h4>November. 2022</h4>
                                <h4>(click here to access video/audio)</h4>
                                <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2022_11.jpg"} alt={"Arthur Fernandez Scarberry"} width={"45%"} height={"45%"} />
                            </center>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <br/>Introductions 0:00:00
                                <br/>Arthur's talk begins : 0:32:15
                                <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1400159638%3Fsecret_token%3Ds-16fcd6nt9Tb&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                                <YouTubePlayer url="https://www.youtube.com/embed/dp4rqsMOPWg" />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="25">
                        <Accordion.Header>
                            <center>
                                <h4>Dr. Littlebrave Beaston - Tsimshian and Makah</h4>
                                <h4>October. 2022</h4>
                                <h4>(click here to access video/audio)</h4>
                                <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony Flyer 2022_10.jpg"} alt={"Dr. Littlebrave Beaston"} width={"45%"} height={"45%"} />
                            </center>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1451716498%3Fsecret_token%3Ds-16fcd6nt9Tb&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                                <YouTubePlayer url="https://www.youtube.com/embed/4wpRcpZzvsY" />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="24">
                        <Accordion.Header>
                            <center>
                                <h4>Leigh Anne Gubatayao - Tsimshian</h4>
                                <h4>Sept. 2022</h4>
                                <h4>(click here to access video/audio)</h4>
                                <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony Flyer 2022_09.jpg"} alt={"Leigh Ann Gubatayao"} width={"45%"} height={"45%"} />
                            </center>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1367591635%3Fsecret_token%3Ds-P9PkLXKThdh&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
                                <YouTubePlayer url="https://www.youtube.com/embed/O3tv7nVtqX8" />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="23">
                        <Accordion.Header>
                            <center>
                                <h4>August 2022</h4>
                                <h4>Leigh Anne Gubatayao - Tsimshian</h4>
                                <h4>(click here to access video/audio)</h4>
                                <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony Delayed.jpg"} alt={"Leigh Ann Gubatayao"} width={"45%"} height={"45%"} />
                            </center>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <h4>Delayed to September 25th 2022</h4>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="22">
                        <Accordion.Header>
                            <center>
                                <h4>Wayne Bitahnii Wilson - Dine'</h4>
                                <h4>July 2022</h4>
                                <h4>(click here to access video/audio)</h4>
                                <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony Delayed.jpg"} alt={"Bitahnii Wayne Wilson"} width={"45%"} height={"45%"} />
                            </center>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <h4>This Ceremony will be presented at a future date</h4>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="21">
                        <Accordion.Header>
                            <center>
                                <h4>Jacqueline Left Hand Bull</h4>
                                <h4>June 2022</h4>
                                <h4>(click here to access video/audio)</h4>
                                <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony Flyer 2022_06.jpeg"} width={"45%"} height={"45%"} alt={"Jacqueline Left Hand Bull"} />
                            </center>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1302440395%3Fsecret_token%3Ds-rFSdM9WJMDc&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
                                <YouTubePlayer url="https://www.youtube.com/embed/i3zVrI6t0Ug" />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>



                    <Accordion.Item eventKey="20">
                        <Accordion.Header>
                            <center>
                                <h4>Chris Bily - May 2022</h4>
                                <h4>(click here to access video/audio)</h4>
                                <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony Flyer 2022_5.jpg"} width={"45%"} height={"45%"} alt={"Chris Bily"} />
                            </center>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1302561133%3Fsecret_token%3Ds-gb7nvfAg4di&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
                                <YouTubePlayer url="https://www.youtube.com/embed/2wC72u2gPRg" />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="19">
                        <Accordion.Header>
                            <center>
                                <h4>Mary and Leigh Anne Gubatayao</h4>
                                <h4>April 2022</h4>
                                <h4>(click here to access video/audio)</h4>
                                <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2022_4.jpg"} width={"45%"} height={"45%"} alt={"Mary and Leigh Anne Gubatayao"} />
                            </center>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1302600145%3Fsecret_token%3Ds-FKMS9dW6EGa&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
                                <YouTubePlayer url="https://www.youtube.com/embed/dJdyqHr_JTE" />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="18">
                        <Accordion.Header>
                            <center>
                                <h4>Max Defender (Jr and Sr)</h4>
                                <h4>March 2022</h4>
                                <h4>(click here to access video/audio)</h4>
                                <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony Flyer 2022_03.jpeg"} width={"45%"} height={"45%"} alt={"Lee Brown"} />
                            </center>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1302638161&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
                                <YouTubePlayer url="https://www.youtube.com/embed/DJekL2VBB4Q" />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="17">
                        <Accordion.Header>
                            <center>
                                <h4>Lee Brown - February 2022</h4>
                                <h4>(click here to access video/audio)</h4>
                                <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2022_02.jpeg"} width={"45%"} height={"45%"} alt={"Lee Brown"} />
                            </center>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1234292038%3Fsecret_token%3Ds-aJuoAzGoF67&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                                <YouTubePlayer url="https://www.youtube.com/embed/ELOvq78Mp_o" />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="16">
                        <Accordion.Header>
                            <center>
                                <h4>Roman Orona - January 2022</h4>
                                <h4>(click here to access video/audio)</h4>
                                <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony Flyer 2022_01.jpg"} width={"45%"} height={"45%"} alt={"Roman Orona "} />
                            </center>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1208268424%3Fsecret_token%3Ds-0uddvanuaET&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                                <YouTubePlayer url="https://www.youtube.com/embed/EK1XkEXnMUs" />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

);
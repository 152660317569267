import React from 'react';
import {SoundCloudPlayer} from './SoundCloudPlayer';
import {YouTubePlayer} from "./YouTubePlayer";
import {Accordion}  from "react-bootstrap";


// @ts-ignore
export const IBC2020 = () => (
    <div>
        <p/>
        <h2>Indigenous Bahá'í Ceremony</h2>
        <Accordion defaultActiveKey="100">

            <Accordion.Item eventKey="4">
                <Accordion.Header>
                    <center>
                        <h4>Dr. Littlebrave Beaston and Mr. John Sargent</h4>
                        <h4>December 2020</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2020_12.jpg"} width={"45%"} height={"45%"} alt={"Dr. Littlebrave Beaston and Mr. John Sargent"} />
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/956855200%3Fsecret_token%3Ds-I6Iq3qtm85c&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/bkXgjCpGfXA" />
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/957735697%3Fsecret_token%3Ds-UQFhWTzSVWB&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/8JSKzpFSU4U" />
                    </div>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <center>
                        <h4>Charlotte Kahn, Nanabah Foguth, Shannon Joseph</h4>
                        <h4>November 2020</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2020_11.jpg"} width={"45%"} height={"45%"} alt={"Charlotte Kahn, Nanabah Foguth, Shannon Joseph"}/>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/962806285%3Fsecret_token%3Ds-iZDkFHxJ37D&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/c6UKuCPwC24" />
                        00:00:00 - Introductions<br/>
                        00:03:51 - Charlotte<br/>
                        00:07:42 - Shannon<br/>
                        00:09:19 - Nanabah<br/>
                        00:22:16 - Toby Joseph Sr.<br/>
                        00:33:39 - Nanabah<br/>
                        00:36:46 - Charlotte<br/>
                        00:45:00 - Shannon<br/>
                        00:50:00 - Nanabah<br/>
                        01:03:31 - Shannon<br/>
                        01:05:45 - Toby Joseph Sr.<br/>
                        01:10:04 - Warriors Song<br/>                       </div>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    <center>
                        <h4>Lee Brown</h4>
                        <h4>October 2020</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2020_10.jpg"} width={"45%"} height={"45%"} alt={"Elder Lee Brown"}/>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/963077692%3Fsecret_token%3Ds-fJsaFfOp4zV&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/5jeWoPPzqJw" />
                    </div>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <center>
                        <h4>Max Defender</h4>
                        <h4>September 2020</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2020_09.jpg"} width={"45%"} height={"45%"} alt={"Max Defender"}/>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/963187996%3Fsecret_token%3Ds-dnHIonvJt3X&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/J6aeYQvGUOg" />
                    </div>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <center>
                        <h4>Mary Gubatayo Introduction to Ceremony</h4>
                        <h4>(click here to access video/audio)</h4>
                        <img src={process.env.PUBLIC_URL+"/images/Ceremony/Ceremony 2020_08.jpg"} width={"45%"} height={"45%"} alt={"Mary Gubatayo"}/>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/957951838%3Fsecret_token%3Ds-8XFNViheUYn&color=%230066ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                        <YouTubePlayer url="https://www.youtube.com/embed/4SuCweh1GLU" />
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
);

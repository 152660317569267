import React from "react";
// @ts-ignore
import styled from 'styled-components';

const YouTubeStyles = styled.div`
  
  .ytStyle {
    font-size: 10px;
    color: #cccccc;
    line-break: anywhere;
    word-break: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
    font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;
    font-weight: 100;
  }
`;

export const YouTubePlayer = (props: { url: string;}) => {

    return (
        <YouTubeStyles>
            <div className='ytStyle'>
                <iframe title="AZIO3" width="560" height="315" src={props.url} frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                 </iframe>
            </div>
        </YouTubeStyles>
    )
}


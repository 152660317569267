import React from 'react';

// @ts-ignore
export const SaggezzaIndex = () => (
    <div>
        <h2>La saggezza di ‘Abdu’l-Bahá
            <br/>Discorsi pronunciati da ‘Abdu’l-Bahá nel 1911
        </h2>
        <ol className="list-group">
            <li className="list-group-item"><a href={"./Numero1"}>1. IL DOVERE DELLA GENTILEZZA E DELLA BENEVOLENZA VERSO GLI ESTRANEI E GLI STRANIERI
                <br/>16-17 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero2"}>2. IL POTERE E IL VALORE DEI PENSIERI GIUSTI DIPENDONO DALL’AZIONE
                <br/>18 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero3"}>3. DIO È IL GRANDE MEDICO COMPASSIONEVOLE, CHE SOLO OPERA LA VERA GUARIGIONE
                <br/>19 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero4"}>4. LA NECESSITÀ DELL’UNIONE FRA I POPOLI DELL’ORIENTE E DELL’OCCIDENTE
                <br/>20 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero5"}>5. DIO COMPRENDE IN SÉ TUTTO, EGLI NON PUÒ ESSERE COMPRESO
                <br/>Venerdì sera, 20 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero6"}>6. LE MESCHINE CAUSE DELLA GUERRA E IL DOVERE DI OGNUNO DI FARE OPERE DI PACE
                <br/>21 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero7"}>7. IL SOLE DELLA VERITÀ
                <br/>22 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero8"}>8. LA LUCE DELLA VERITÀ BRILLA ORA IN ORIENTE E IN OCCIDENTE
                <br/>23 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero9"}>9. L’AMORE UNIVERSALE
                <br/>24 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero10"}>10. LA PRIGIONIA DI ‘ABDU’L-BAHÁ
                <br/>Avenue de Camoëns 4
                <br/>Mercoledì, 25 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero11"}>11. IL PIÙ GRAN DONO CHE DIO HA FATTO ALL’UOMO
                <br/>Giovedì, 26 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero12"}>12. LE NUVOLE CHE VELANO IL SOLE DELLA VERITÀ
                <br/>Avenue de Camoëns 4
                <br/>venerdì mattina, 27 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero13"}>13. I PREGIUDIZI RELIGIOSI
                <br/>27 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero14"}>14. I BENEFICI DI DIO ALL’UOMO
                <br/>Avenue de Camoëns 4
                <br/>27 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero15"}>15. LA BELLEZZA E L’ARMONIA DELLA DISSIMIGLIANZA
                <br/>28 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero16"}>16. IL VERO SIGNIFICATO DELLE PROFEZIE SULLA VENUTA DI CRISTO
                <br/>30 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero17"}>17. LO SPIRITO SANTO È IL POTERE INTERMEDIARIO TRA DIO E L’UOMO
                <br/>Avenue de Camoëns 4
                <br/>31 ottobre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero18"}>18. LE DUE NATURE DELL’UOMO
                <br/>1 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero19"}>19. IL PROGRESSO MATERIALE E SPIRITUALE
                <br/>2 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero20"}>20. L’EVOLUZIONE DELLA MATERIA E LO SVILUPPO DELL’ANIMA
                <br/>3 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero21"}>21. LE CONFERENZE SPIRITUALI A PARIGI
                <br/>4 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero22"}>22. I DUE GENERI DI LUCE
                <br/>5 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero23"}>23. L’ASPIRAZIONE SPIRITUALE NELL’OCCIDENTE
                <br/>6 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero24"}>24. CONFERENZA IN UNO STUDIO A PARIGI
                <br/>6 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero25"}>25. BAHÁ’U’LLÁH
                <br/>7 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero26"}>26. LE BUONE IDEE DEVONO ESSERE MESSE IN AZIONE
                <br/>8 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero27"}>27. IL VERO SIGNIFICATO DEL BATTESIMO DELL’ACQUA E DEL FUOCO
                <br/>9 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero28"}>28. DISCORSO A «L’ALLEANZA SPIRITUALISTA»
                <br/>Sala dell’Ateneo
                <br/>St. Germain, Parigi, 9 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero29"}>29. L’EVOLUZIONE DELLO SPIRITO
                <br/>Rue Greuse, 15, Parigi
                <br/>10 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero30"}>30. I DESIDERI E LE PREGHIERE DI ‘ABDU’L-BAHÁ
                <br/>15 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero31"}>31. IL CORPO, L’ANIMA E LO SPIRITO
                <br/>Avenue de Camoëns 4, Parigi
                <br/>17 novembre 1911
            </a>
            </li>
            <li className="list-group-item"><a href={"./Numero32"}>32. I BAHÁ’Í DEVONO DARE CUORE E ANIMA ALL’ALTA OPERA DI PORTARE NEL MONDO UNA MIGLIORE CONDIZIONE
                <br/>19 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero33"}>33. LA CALUNNIA
                <br/>20 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero34"}>34. NON VI POSSONO ESSERE VERA FELICITÀ E PROGRESSO SENZA SPIRITUA-LITÀ
                <br/>21 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero35"}>35. IL DOLORE E LA TRISTEZZA
                <br/>22 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero36"}>36. LE VIRTÙ E I SENTIMENTI UMANI PERFETTI
            <br/>23 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero37"}>37. LA CRUDELE INDIFFERENZA DELLA GENTE VERSO LE SOFFERENZE
                DEI POPOLI STRANIERI
                <br/>24 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero38"}>38. L’ESIGUITÀ DEL NOSTRO NUMERO NON CI DEVE SCORAGGIARE
                <br/>25 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero39"}>39. DISCORSO PRONUNCIATO DA ‘ABDU’L-BAHÁ NELLA CHIESA DEL PASTORE WAGNER «FOYER DE L’ÂME» A PARIGI
                <br/>26 novembre 1911</a>
            </li>
            <li className="list-group-item">
                <h5>PARTE SECONDA</h5>
                <p>Gli undici Principi tratti dagli Insegnamenti di Bahá’u’lláh spiegati da ‘Abdu’l-Bahá a Parigi.</p>
                <p>La ricerca della verità.</p>
                <p>L’unità del genere umano.</p>
                <p>La religione dev’essere causa di amore e di affetto.</p>
                <p>L’abolizione dei pregiudizi.</p>
                <p>La parificazione dei mezzi di sussistenza.</p>
                <p>L’uguaglianza degli uomini davanti alla legge.</p>
                <p>La pace universale.</p>
                <p>La non interferenza della religione e della politica.</p>
                <p>La parità dei sessi e l’educazione delle donne.</p>
                <p>Il potere dello Spirito Santo.</p>
            </li>
            <li className="list-group-item"><a href={"./Numero40"}>40. LA SOCIETÀ TEOSOFICA, PARIGI</a>
            </li>
            <li className="list-group-item"><a href={"./Numero41"}>41. IL PRIMO PRINCIPIO: LA RICERCA DELLA VERITÀ
                <br/>Avenue de Camoëns 4
                <br/>10 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero42"}>42. IL SECONDO PRINCIPIO: L’UNITÀ DELLA RAZZA UMANA
                <br/>11 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero43"}>43. IL TERZO PRINCIPIO</a>
            </li>
            <li className="list-group-item"><a href={"./Numero44"}>44. IL QUARTO PRINCIPIO: L’ACCETTAZIONE DEI RAPPORTI
                FRA LA RELIGIONE E LA SCIENZA
                <br/>Avenue de Camoëns 4, Parigi
                <br/>12 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero45"}>45. IL QUINTO PRINCIPIO: L’ABOLIZIONE DEI PREGIUDIZI
                <br/>Avenue de Camoëns 4, Parigi
                <br/>10 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero46"}>46. IL SESTO PRINCIPIO: I MEZZI DI SUSSISTENZA
                <br/>Avenue de Camoëns 4, Parigi</a>
            </li>
            <li className="list-group-item"><a href={"./Numero47"}>47. IL SETTIMO PRINCIPIO: L’UGUAGLIANZA DEGLI UOMINI</a>
            </li>
            <li className="list-group-item"><a href={"./Numero48"}>48. L’OTTAVO PRINCIPIO: LA PACE UNIVERSALE
                <br/>Avenue de Camoëns 4, Parigi</a>
            </li>
            <li className="list-group-item"><a href={"./Numero49"}>49. IL NONO PRINCIPIO: LA NON INTERFERENZA
                DELLA RELIGIONE NELLA POLITICA
                <br/>Avenue de Camoëns 4 Parigi
                <br/>17 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero50"}>50. IL DECIMO PRINCIPIO: LA PARITÀ DEI SESSI
                <br/>Avenue de Camoëns 4, Parigi
                <br/>14 Novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero51"}>51. L’UNDICESIMO PRINCIPIO: IL POTERE DELLO SPIRITO SANTO
                <br/>Avenue de Camoëns 4, Parigi
                <br/>18 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero52"}>52. QUESTA CAUSA, GRANDE E GLORIOSA
                <br/>Avenue de Camoëns 4, Parigi
                <br/>28 novembre 1911</a>
            </li>
            <li className="list-group-item"><a href={"./Numero53"}>53. L’ULTIMA RIUNIONE
                <br/>Rue Greuze, 15, Parigi
                <br/>1° dicembre 1911</a>
            </li>
            <li className="list-group-item"><h5>PARTE TERZA</h5>
            </li>
            <li className="list-group-item"><a href={"./Numero54"}>54. DISCORSO DI ‘ABDU’L-BAHÁ NELLA CASA DELLE RIUNIONI DEGLI AMICI
                <br/>St. Martin’s Lane, Londra
                <br/>12 gennaio 1913
            </a>
            </li>
            <li className="list-group-item"><a href={"./Numero55"}>55. LA PREGHIERA
                <br/>Cadogan Gardens 99, Londra
                <br/>26 dicembre 1912 </a>
            </li>
            <li className="list-group-item"><a href={"./Numero56"}>56. IL MALE</a>
            </li>
            <li className="list-group-item"><a href={"./Numero57"}>57. IL PROGRESSO DELL’ANIMA</a>
            </li>
            <li className="list-group-item"><a href={"./Numero58"}>58. LE QUATTRO SPECIE D’AMORE
                <br/>Cadogan Gardens 97, Londra
                <br/>Sabato, 4 gennaio 1912
            </a>
            </li>
            <li className="list-group-item"><a href={"./Numero59"}>59. TAVOLA RIVELATA DA ‘ABDU’L-BAHÁ
                <br/>28 agosto 1913</a>
            </li>

        </ol>
     </div>
)

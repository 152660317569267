
import React, {MouseEvent } from "react";
import styled from "styled-components";
import { Carousel } from "react-bootstrap"


const CarouselStyle = styled.div`
  .carousel-caption {
    top: 0;
    bottom: auto;
  }
  
  .carousel-item {
    //style: {{'height':{$imageHeight} 'width':{$imageWidth}
  }

`;

interface SlideShowProps{
    images: string[];
    quotes: string[];
}



    export const SlideShow: React.FC<SlideShowProps> = ({images, quotes }) => {
    let $image_width = "100%";
    let $image_height = "auto";


    let carousel_items = images.map(function(image, i) {


        const handleImageMouseEvent = (e: MouseEvent<HTMLImageElement>) => {
            e.preventDefault();

        }
         return (
            <Carousel.Item key={(i % quotes.length).toString()} >
                <img src={process.env.PUBLIC_URL + image} alt={image} width={$image_width}
                     height={$image_height} onContextMenu={handleImageMouseEvent} contextMenu={"myMenu"}/>
                <Carousel.Caption>
                    <h2>{process.env.PUBLIC_URL + quotes[i % quotes.length]}</h2>
                </Carousel.Caption>
            </Carousel.Item>
        );
    });

    return (
        <div>
            {/*<audio src={ "http://www.sousound.com/music/healing/healing_01.mp3"} loop autoPlay />*/}
            <CarouselStyle>
                <Carousel >
                    { carousel_items }
                 </Carousel>
            </CarouselStyle>

        </div>
    )
}



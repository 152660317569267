import React from 'react';
import {Accordion} from 'react-bootstrap';
import {SoundCloudPlayer} from './SoundCloudPlayer';
import {YouTubePlayer} from "./YouTubePlayer";
import {June2023} from "./Media/06_2023";

// @ts-ignore
export const IBC2023 = () => (
    <div>
        <p/>
        <h2>Indigenous Bahá'í Ceremony 2023</h2>
        <Accordion defaultActiveKey="100">
            <Accordion.Item eventKey="38">
                <Accordion.Header>
                    <center>
                        <h4>Kassei, Naomi Michalsen - Tlingit</h4>
                        <h4>Sunday 10th December 2023 2:00pm PST</h4>
                        <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2023_12a.jpg"}
                             alt={"Naomi Michalsen"} width={"45%"} height={"45%"}/>
                        <h4>(click here to show/hide video/audio)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <h4>
                            <SoundCloudPlayer
                                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1693771407%3Fsecret_token%3Ds-16fcd6nt9Tb&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                            <YouTubePlayer url="https://www.youtube.com/embed/lxAfFVOZNj8"/>
                        </h4>
                    </div>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="37">
                <Accordion.Header>
                    <center>
                        <h4>Leonard Bell - African American and Mary Gubatayao - Tsimshian/</h4>
                        <h4>Sunday 5th November 2023 2:00pm PST</h4>
                        <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2023_11.jpeg"}
                             alt={"LEONARD AND MARY"} width={"45%"} height={"45%"}/>
                        <h4>(click here to show/hide video/audio)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <h4>
                            <SoundCloudPlayer
                                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1728906183&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/>
                            <YouTubePlayer url="https://www.youtube.com/embed/BEoHY2gW-VU"/>
                        </h4>
                    </div>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="36">
                <Accordion.Header>
                    <center>
                        <h4>Cleven Ticeson - African American and Indian Drumming</h4>
                        <h4>Sunday 24th September 2023</h4>
                        <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2023_09.jpeg"}
                             alt={"African American and Indian Drumming"} width={"45%"} height={"45%"}/>
                        <h4>(click here to show/hide video/audio)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <h4>
                            <YouTubePlayer url="https://www.youtube.com/embed/IHKWb-XrMT8?si=qtY55JLzSy0csMw4"/>
                        </h4>
                    </div>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="35">
                <Accordion.Header>
                    <center>
                        <h4>John Pappan- Omaha and Pawnee</h4>
                        <h4>Sunday 24th September 2023 2:00pm PST</h4>
                        <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2023_09.jpeg"}
                             alt={"John Pappan"} width={"45%"} height={"45%"}/>
                        <h4>(click here to show/hide video/audio)</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <h4>
                            <SoundCloudPlayer
                                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1693691235%3Fsecret_token%3Ds-16fcd6nt9Tb&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                            <YouTubePlayer url="https://www.youtube.com/embed/dEk5zX6SdPg"/>
                        </h4>
                    </div>
                </Accordion.Body>
            </Accordion.Item>



                <Accordion.Item eventKey="34">
                    <Accordion.Header>
                        <center>
                            <h4>Dr. Littlebrave Beaston- Tsminshian and Makah</h4>
                            <h4>Sunday 23rd July 2023</h4>
                            <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2023_07.jpeg"}
                                 alt={"Missing and Murdered"} width={"45%"} height={"45%"}/>
                            <h4>(click here to show/hide video/audio)</h4>
                        </center>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <SoundCloudPlayer
                                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1576915287%3Fsecret_token%3Ds-16fcd6nt9Tb&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                            <YouTubePlayer url="https://www.youtube.com/embed/iOH3g2urRPc"/>
                            <ol>

                                <h4>Links/Videos Mentioned in Dr. Littlebrave Beaston's Presentation</h4>

                                <li><a href={process.env.PUBLIC_URL + "/Documents/AncientCedars MMIWG.pdf"}>Dr.
                                    Littlebrave Beaston's Presentation</a></li>

                                <li><a
                                    href={"https://www.uihi.org/resources/missing-and-murdered-indigenous-women-girls/"}>Urban
                                    Indian Health Institiute - A snapshot of data from 71 urban cities in the United
                                    States</a></li>

                                <li><a href={"https://www.uihi.org/projects/our-bodies-our-stories/"}>Urban Indian
                                    Health Institiute - Our Bodies, Our Stories/</a></li>

                                <li><a href={"https://www.uihi.org/resources/mmiwg-we-demand-more/"}>Urban Indian Health
                                    Institiute - MMIWG: We Demand More</a></li>

                                <li><a
                                    href={"https://www.seattletimes.com/seattle-news/homeless/survey-reveals-high-rates-of-sexual-assault-among-native-american-women-many-of-them-homeless/"}>Seattle
                                    Times - Nearly every Native American woman in Seattle survey said she was raped or
                                    coerced into sex</a></li>

                                <li><a
                                    href={"https://www.bia.gov/service/mmu/missing-and-murdered-indigenous-people-crisis"}>US
                                    Department of Interior (Indian Affairs) Missing and Murdered Indigenous People
                                    Crisis</a></li>

                                <li><a href={"https://www.ojp.gov/pdffiles1/nij/grants/223691.pdf"}>US Department of
                                    Justice - Violence Against American Indian and Alaska Native Women and the Criminal
                                    Justice Response: What is Known</a></li>

                                <li><a href={"https://www.cdc.gov/violenceprevention/sexualviolence/rpe/index.html"}>CDC
                                    - Rape Prevention and Education Program</a></li>

                                <li><a href={"https://www.miwsac.org/"}>The Minnesota Indian Women’s Sexual Assault
                                    Coalition</a></li>

                                <li><a href={"https://www.facebook.com/NativeJournalists/"}>Facebook - Native American
                                    Journalist Association - Native Journalist</a></li>

                                <li><a href={"https://najanewsroom.com/reporting-guides/"}>Native American Journalists
                                    Association - Reporting Guides</a></li>

                                <li><a href={"https://www.somebodysdaughter.com/"}>Somebody's Daughter</a></li>

                                <li><a href={" https://mshoop.org/"}>Mending the Sacred Hoop</a></li>

                                <li>
                                    REDress Project by Jaime Black - March - 2011
                                    <YouTubePlayer url="https://www.youtube.com/embed/iaX8ks7CcPg"/>
                                </li>

                                <li>
                                    Violence Against American Indian and Alaska Native Women and Men
                                    <YouTubePlayer url="https://www.youtube.com/embed/ESADNNHM4II"/>
                                </li>

                                <li>
                                    The REDress Project at the National Museum of the American Indian
                                    <YouTubePlayer url="https://www.youtube.com/embed/lH7FuxzrFvs"/>
                                </li>

                                <li>
                                    Finding Dawn
                                    <YouTubePlayer url="https://www.youtube.com/embed/f-0Z-UoQ3VY"/>
                                </li>

                                <li>
                                    1986 - Ruby Guyatayao on Bahá'í Focus
                                    <YouTubePlayer url="https://www.youtube.com/embed/jazgs3Qs9qI"/>
                                </li>
                            </ol>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="33">
                    <Accordion.Header>
                        <center>
                            <h4>International Convention</h4>
                            <h4>Deloria Bighorn- Yankton Sioux & Chickasaw</h4>
                            <h4>Yuxgitsiy George Holly- Deg Xit'an (Athabaskan)</h4>
                            <h4>Nanabah Foguth - Diné - Navajo</h4>
                            <h4>Sunday 4th June. 2023</h4>
                            <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2023_05.jpg"}
                                 alt={"International Convention"} width={"45%"} height={"45%"}/>
                            <h4>(click here to show/hide video/audio)</h4>
                        </center>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <SoundCloudPlayer
                                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1531063153%3Fsecret_token%3Ds-16fcd6nt9Tb&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                            <YouTubePlayer url="https://www.youtube.com/embed/4TvbQco3KYs"/>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="32">
                    <Accordion.Header>
                        <center>
                            <h4>African American Indian Drumming</h4>
                            <h4>Portland</h4>
                            <h4>Sunday 21st May. 2023</h4>
                            <h4>(click here to show/hide video/audio)</h4>
                            <img src={process.env.PUBLIC_URL + "/images/Ceremony/African American Indian Drumming.jpeg"}
                                 alt={"International Convention"} width={"45%"} height={"45%"}/>
                        </center>
                    </Accordion.Header>
                    <Accordion.Body>
                        {June2023()}
                        {/*    <div>*/}
                        {/*    /!*<SoundCloudPlayer url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1523678059%3Fsecret_token%3Ds-16fcd6nt9Tb&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>*!/*/}
                        {/*    /!*<YouTubePlayer url="https://www.youtube.com/embed/6gzukxYlk0c" />*!/*/}
                        {/*</div>*/}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="31">
                    <Accordion.Header>
                        <center>
                            <h4>Waniya Locke - Ahtna Dene, Anishinaabe, Lakota, and Dakota.</h4>
                            <h4>Sunday 30th April. 2023 2:00pm PST</h4>
                            <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2023_04.jpg"}
                                 alt={"International Convention"} width={"45%"} height={"45%"}/>
                            <h4>(click here to show/hide video/audio)</h4>
                        </center>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <SoundCloudPlayer
                                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1505242447%3Fsecret_token%3Ds-16fcd6nt9Tb&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                            <YouTubePlayer url="https://www.youtube.com/embed/u38zP-sPei0"/>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="30">
                    <Accordion.Header>
                        <center>
                            <h4>Roderick Harris - Nooksack</h4>
                            <h4>Sunday 26th March. 2023 2:00pm PST</h4>
                            <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2023_03.jpg"}
                                 alt={"Roderick Harris"} width={"45%"} height={"45%"}/>
                            <h4>(click here to show/hide video/audio)</h4>
                        </center>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <SoundCloudPlayer
                                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1500526267%3Fsecret_token%3Ds-16fcd6nt9Tb&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                            <YouTubePlayer url="https://www.youtube.com/embed/469ReOirA6E"/>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>


                <Accordion.Item eventKey="29">
                    <Accordion.Header>
                        <center>
                            <h4>Max Defender - Dakota</h4>
                            <h4>Sunday 26th February. 2023 2:00pm PST</h4>
                            <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2023_02.jpg"}
                                 alt={"Max Defender"} width={"45%"} height={"45%"}/>
                            <h4>(click here to show/hide video/audio)</h4>
                        </center>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <SoundCloudPlayer
                                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1458884266%3Fsecret_token%3Ds-16fcd6nt9Tb&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                            <YouTubePlayer url="https://www.youtube.com/embed/SXo6blP8vY0"/>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>


                <Accordion.Item eventKey="28">
                    <Accordion.Header>
                        <center>
                            <h4>Mr. Yuxgitsiy George Holly - Deg Xit'an (Athabaskan)</h4>
                            <h4>January. 2023 (click here to access video/audio)</h4>
                            <img src={process.env.PUBLIC_URL + "/images/Ceremony/Ceremony Flyer 2023_01.jpg"}
                                 alt={"George Holly"} width={"45%"} height={"45%"}/>
                            <h4>(click here to show/hide video/audio)</h4>
                        </center>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <SoundCloudPlayer
                                url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1451676373%3Fsecret_token%3Ds-16fcd6nt9Tb&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"/>
                            <YouTubePlayer url="https://www.youtube.com/embed/UBryaOTr7hE"/>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
        </Accordion>
    </div>
)


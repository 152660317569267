import React from 'react';
import {YouTubePlayer} from "./YouTubePlayer";
import {Accordion} from "react-bootstrap";


// @ts-ignore
export const Team = () => (
    <div>
        <p/>
        <h3>Team Information</h3>
        <p/>
        <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <center>
                        <h4>Using the Microsoft Edge Browser to Read the Writings aloud</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <ol>
                            <li>Watch the video below Full Screen and if you like it then follow the instructions below</li>
                            <li><a href="https://www.microsoft.com/en-us/edge/features">Go to the Microsoft Edge website.</a></li>
                            <li>Select macOS or Windows from the Download drop-down menu, then select Download (it probably takes you to the correct OS Version automatically so you may not have to select).</li>
                            <li> Install the Edge Browder on your system</li>
                            <li>Open the Edge Browser and to to https://ancient-cedars.org</li>
                            <li>In the quick links select writings</li>
                            <li>Turn on Read Aloud it's an option in the Edge Browser Menu</li>
                            <li>Listen to A Special Measure of Love</li>
                            <li>Turn off Read Aloud when you are done</li>
                        </ol>
                        <YouTubePlayer url={"https://www.youtube.com/embed/sh01cIVPiKU"}/>
                    </center>
                </Accordion.Body>
            </Accordion.Item>
         </Accordion>
    </div>
)

import React from 'react';
import {Accordion} from "react-bootstrap";

export const TABLETS_DIVINE_PLAN = () => (
    <div>
        <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <center>
                        <h4>Overview</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>
                        <p>Fourteen letters addressed by ‘Abdu’l‑Bahá to the North American Bahá’í community as a whole
                            as well as by region—Canada and the Northeastern, Southern, Central, and Western United
                            States. The first eight letters were written between 26 March and 22 April 1916, the
                            remainder between 2 February and 8 March 1917.</p>
                        <a href={process.env.PUBLIC_URL+"/Documents/tablets-divine-plan.pdf"}>Click to Download pdf</a>
                    </center>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    <center>
                        <h4>Tablet to the Bahá’ís of the Northeastern States</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <p>Revealed on March 26, 1916, in ‘Abdu’l‑Bahá’s room at the house in Bahjí, addressed to
                            the Bahá’ís of nine Northeastern States of the United States: Maine, Massachusetts, New
                            Hampshire, Rhode Island, Connecticut, Vermont, Pennsylvania, New Jersey and New
                            York.</p>
                        <h4><i>O ye heavenly heralds:</i></h4>
                        <p>These are the days of Naw-Rúz. I am always thinking of those kind friends! I beg for each
                            and all of you confirmations and assistance from the threshold of oneness, so that those
                            gatherings may become ignited like unto candles, in the republics of America, enkindling
                            the light of the love of God in the hearts; thus the rays of the heavenly teachings may
                            begem and brighten the states of America like the infinitude of immensity with the stars
                            of the Most Great Guidance.</p>
                        <p>The Northeastern States on the shores of the Atlantic—Maine, New Hampshire,
                            Massachusetts, Rhode Island, Connecticut, Vermont, Pennsylvania, New Jersey and New
                            York—in some of these states believers are found, but in some of the cities of these
                            states up to this date people are not yet illumined with the lights of the Kingdom and
                            are not aware of the heavenly teachings; therefore, whenever it is possible for each one
                            of you, hasten ye to those cities and shine forth like unto the stars with the light of
                            the Most Great Guidance. God says in the glorious Qur’án: “The soil was black and dried.
                            Then we caused the rain to descend upon it and immediately it became green, verdant, and
                            every kind of plant sprouted up luxuriantly.”[1] In other words, He says the earth is
                            black, but when the spring showers descend upon it that black soil is quickened, and
                            variegated flowers are pushed forth. This means the souls of humanity belonging to the
                            world of nature are black like unto the soil. But when the heavenly outpourings descend
                            and the radiant effulgences appear, the hearts are resuscitated, are liberated from the
                            darkness of nature and the flowers of divine mysteries grow and become luxuriant.
                            Consequently man must become the cause of the illumination of the world of humanity and
                            propagate the holy teachings revealed in the sacred books through the divine
                            inspiration. It is stated in the blessed Gospel: Travel ye toward the East and toward
                            the West and enlighten the people with the light of the Most Great Guidance, so that
                            they may take a portion and share of eternal life.[2] Praise be to God, that the
                            Northeastern States are in the utmost capacity. Because the ground is rich, the rain of
                            the divine outpouring is descending. Now you must become heavenly farmers and scatter
                            pure seeds in the prepared soil. The harvest of every other seed is limited, but the
                            bounty and the blessing of the seed of the divine teachings is unlimited. Throughout the
                            coming centuries and cycles many harvests will be gathered. Consider the work of former
                            generations. During the lifetime of Jesus Christ the believing, firm souls were few and
                            numbered, but the heavenly blessings descended so plentifully that in a number of years
                            countless souls entered beneath the shadow of the Gospel. God has said in the Qur’án:
                            “One grain will bring forth seven sheaves, and every sheaf shall contain one hundred
                            grains.”[3] In other words, one grain will become seven hundred; and if God so wills He
                            will double these also. It has often happened that one blessed soul has become the cause
                            of the guidance of a nation. Now we must not consider our ability and capacity, nay,
                            rather, we must fix our gaze upon the favors and bounties of God, in these days, Who has
                            made of the drop a sea, and of the atom a sun.</p>
                        <p>Upon you be greeting and praise!</p>
                        <p>
                            [1] Qur’án 22:5<br/>
                            [2] Mark 16:15<br/>
                            [3] Qur’án 2:261<br/>
                        </p>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <center>
                        <h4>Tablet to the Bahá’ís of the Southern States</h4>
                    </center>
                </Accordion.Header>
                <Accordion.Body>
                    <center>

                        <p>Revealed on March 27, 1916, in the garden adjacent to the Shrine of Bahá’u’lláh,
                            addressed to the Bahá’ís of sixteen Southern States of the United States: Delaware,
                            Maryland, Virginia, West Virginia, North Carolina, South Carolina, Georgia, Florida,
                            Alabama, Mississippi, Tennessee, Kentucky, Louisiana, Arkansas, Oklahoma and Texas.</p>
                        <h4><i>O ye heralds of the Kingdom of God:</i></h4>
                        <p>A few days ago an epistle was written to those divine believers, but because these days
                            are the days of Naw-Rúz, you have come to my mind and I am sending you this greeting for
                            this glorious feast. All the days are blessed, but this feast is the national fête of
                            Persia. The Persians have been holding it for several thousand years past. In reality
                            every day which man passes in the mention of God, the diffusion of the fragrances of God
                            and calling the people to the Kingdom of God, that day is his feast. Praise be to God
                            that you are occupied in the service of the Kingdom of God and are engaged in the
                            promulgation of the religion of God by day and by night. Therefore all your days are
                            feast days. There is no doubt that the assistance and the bestowal of God shall descend
                            upon you.</p>
                        <p>In the Southern States of the United States, the friends are few, that is, in Delaware,
                            Maryland, Virginia, West Virginia, North Carolina, South Carolina, Georgia, Florida,
                            Alabama, Mississippi, Tennessee, Kentucky, Louisiana, Arkansas, Oklahoma and Texas.
                            Consequently you must either go yourselves or send a number of blessed souls to those
                            states, so that they may guide the people to the Kingdom of Heaven. One of the holy
                            Manifestations, addressing a believing soul, has said that, if a person become the cause
                            of the illumination of one soul, it is better than a boundless treasury. “O ‘Alí! If God
                            guide, through thee, one soul, it is better for thee than all the riches!” Again He
                            says, “Direct us to the straight path!”[1] that is, Show us the right road. It is also
                            mentioned in the Gospel: Travel ye to all parts of the world and give ye the glad
                            tidings of the appearance of the Kingdom of God.[2] </p>
                        <p>In brief, I hope you will display in this respect the greatest effort and magnanimity. It
                            is assured that you will become assisted and confirmed. A person declaring the glad
                            tidings of the appearance of the realities and significances of the Kingdom is like unto
                            a farmer who scatters pure seeds in the rich soil. The spring cloud will pour upon them
                            the rain of bounty, and unquestionably the station of the farmer will be raised in the
                            estimation of the lord of the village, and many harvests will be gathered.</p>
                        <p>Therefore, ye friends of God! Appreciate ye the value of this time and be ye engaged in
                            the sowing of the seeds, so that you may find the heavenly blessing and the lordly
                            bestowal. Upon you be Bahá’u’l-Abhá!</p>
                        <p>
                            [1] Qur’án 1:6<br/>
                            [2] Cf.Mark 16:15<br/>
                        </p>                    </center>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>

    </div>
)
